import './FoodsList.css';

import { Fragment, useState, useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import arrowLeft from '../assets/back.svg';

import SquareButton from './ForwardButton';

import minimizeSVG from '../assets/up_arrow.svg';

const getFoodChildren = (foods, foodID) => {
  const food = foods.find(food => food.id === foodID);

  if (food.children) {
    return food.children;
  } else {
    return [food];
  }
};

const FoodsList = ({ foods, selectedFoods, lang, onSelectFood, onSkipMeal, meal, renderPortionPicker, onBack, pollQuestionText }) => {
  const { t } = useTranslation();

  const [showFoods, setShowFoods] = useState(true);
  const [showDrinks, setShowDrinks] = useState(true);
  const [disableScrollButton, setDisableScrollButton] = useState(false);

  const foodsListRef = useRef(null);

  let filteredFoods = [...foods];

  if (selectedFoods.length !== 0) {
    filteredFoods = selectedFoods.reduce((prevValue, currentValue, index) => {
      return getFoodChildren(prevValue, selectedFoods[index]);
    }, foods);
  }

  const handleToggleFoodsList = () => {
    setShowFoods(!showFoods);
  }
  const handleToggleDrinksList = () => {
    setShowDrinks(!showDrinks);
  }

  const handleClickDownButton = (ref, disabled) => {
    if (ref.current === null || disabled) {
      return;
    }

    ref.current.scrollTo({
      top: ref.current.scrollHeight,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    if (foodsListRef.current) {
      foodsListRef.current.addEventListener('scroll', (e) => {
        // disable when scroll is at the bottom
        if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
          setDisableScrollButton(true);
        } else {
          setDisableScrollButton(false);
        }
      });
    }
  }, [foodsListRef]);

  const handleSelectFood = (foodID, categoryID, containerRef) => {
    if (containerRef?.current) {
      containerRef.current.scrollTo({
        top: 0,
      });
    }

    onSelectFood(foodID, categoryID);
  }

  const FoodPath = () => (
    <span className='food-selector__route'>
    {
      selectedFoods.map((id, index) => {
        if (index === selectedFoods.length - 1) {
          return <span key={index}>{t('food_' + id)}</span>;
        }
        return <span key={index}>{t('food_' + id)}<span className='food-selector__route__icon'>></span></span>
      })
    }
  </span>)

  const lastFoodSelected = filteredFoods[0]?.id === selectedFoods[selectedFoods.length - 1];

  if (filteredFoods.length === 1 && lastFoodSelected && !filteredFoods[0].children) {
    return (
      <Fragment>
        <div className='food-selector__header'>
          <span className='poll-question poll-question--small'>
          <Trans i18nKey={'quantity_you_ate'} t={t}>
            ¿Qué cantidad <span className='color-blue'>tomaste</span>?
          </Trans>
          </span>
          <FoodPath />
          <button onClick={() => onBack()} className='btn btn-back'>
            <img width={22} height={17} src={arrowLeft}/>
            <span>{t('back')}</span>
          </button>
        </div>

        <div className='food-selector__header food-selector__header--mobile hide-desktop'>
          <span className='poll-question poll-question--small'>
            <Trans i18nKey={'quantity_you_ate'} t={t}>
              ¿Qué cantidad <span className='color-blue'>tomaste</span>?
            </Trans>
          </span>
          <div className='food-selector__header--mobile__inner'>
            <FoodPath />
            <SquareButton
              position='left'
              size='small'
              enableButton={true}
              onClickNext={() => onBack()}
            />
          </div>
        </div>
        {
          renderPortionPicker(filteredFoods[0])
        }
      </Fragment>
    )
  }

  return (
    <Fragment>
    {
      selectedFoods.length > 0 && (
        <Fragment>
          <div className='food-selector__header'>
            <span className='poll-question poll-question--small'>
              {pollQuestionText}
            </span>
            <FoodPath />
            <button onClick={() => onBack()} className='btn btn-back'>
              <img width={22} height={17} src={arrowLeft}/>
              <span>{t('back')}</span>
            </button>
          </div>
          <div className='food-selector__header food-selector__header--mobile hide-desktop'>
            <span className='poll-question poll-question--small'>
              {pollQuestionText}
            </span>
            <div className='food-selector__header--mobile__inner'>
              <FoodPath />
              <SquareButton
                position='left'
                size='small'
                enableButton={true}
                onClickNext={() => onBack()}
              />
            </div>
          </div>
        </Fragment>
      )
    }
    {
      selectedFoods.length === 0 && (
        <div className='food-selector__header food-selector__header--mobile'>
          <span key={meal} className='poll-question poll-question--small'>
            <Trans i18nKey={`select_food_that_you_ate_${meal}`} t={t}>
              Selecciona uno de los <span className='color-blue-animate'>alimentos</span> o <span className='color-blue-animate'>bebidas</span> que tomaste ayer para <span className='color-blue-animate'>desayunar</span>
            </Trans>
          </span>
          <button
            className='btn btn-primary btn-primary--small'
            type='button'
            onClick={() => onSkipMeal()}
          >
            {t('i_didnt_eat')}
          </button>
        </div>
      )
    }
      <div key={meal} ref={foodsListRef} className='foods-list blue-scroll-container'>
        {
          selectedFoods.length === 0 && (
            <div className='foods-list__btn-down__wrapper show-desktop'>
              <button disabled={disableScrollButton} onClick={() => handleClickDownButton(foodsListRef, disableScrollButton)} className='foods-list__btn-down'></button>
            </div>
          )
        }
        {
          selectedFoods.length === 0 && (
            <div className='foods-list--categories'>
              
                <div>
                  <div className='foods-list--header-category'>
                    <span>{t('food')}</span>
                    <button onClick={() => handleToggleFoodsList()}><object type='image/svg+xml' data={minimizeSVG}/></button>
                  </div>
                  <div className='foods-list foods-list__col'>
                  {
                    showFoods && foods && foods.length !== 0 && foods[0].children.map((food, i) => {
                      return (
                        <button onClick={() => handleSelectFood(food.id, 1, foodsListRef)} key={i} className='food-box'>
                          <div className='food-box__inner'>
                            <div>
                              <img
                                src={`./locales/assets/foods/${food.id}.png`}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = './locales/assets/foods/placeholder.png';
                                }}
                                alt={food.name[lang]}
                              />
                            </div>
                            <span>
                              {food.name[lang]}
                            </span>
                          </div>
                        </button>
                      )
                    })
                  }
                  </div>
                </div>
                <div>
                  <div className='foods-list--header-category'>
                    <span>{t('drinks')}</span>
                    <button onClick={() => handleToggleDrinksList()}><object type='image/svg+xml' data={minimizeSVG}/></button>
                  </div>
                  <div className='foods-list foods-list__col'>
                  {
                    showDrinks && foods && foods.length !== 0 && foods[1].children.map((food, i) => {
                      return (
                        <button onClick={() => handleSelectFood(food.id, 2, foodsListRef)} key={i} className='food-box'>
                          <div className='food-box__inner'>
                            <div>
                              <img
                                src={`./locales/assets/foods/${food.id}.png`}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = './locales/assets/foods/placeholder.png';
                                }}
                                alt={food.name[lang]}
                              />
                            </div>
                            <span>
                              {food.name[lang]}
                            </span>
                          </div>
                        </button>
                      )
                    })
                  }
                  </div>
                </div>

            </div>
          )
        }
        {
          selectedFoods.length > 0 && filteredFoods.map((food, i) => {
            // const foodImage = require(`../assets/foods/${food.id}.png`);

            return (
              <button onClick={() => handleSelectFood(food.id, undefined, foodsListRef)} key={i} className='food-box'>
                <div className='food-box__inner'>
                  <div>
                    <img
                      src={`./locales/assets/foods/${food.id}.png`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = './locales/assets/foods/placeholder.png';
                      }}
                      alt={food.name[lang]}
                    />
                  </div>
                  <span>
                    {food.name[lang]}
                  </span>
                </div>
              </button>
            );
          })
        }
      </div>
    </Fragment>
  );
};

export default FoodsList;
