import { useState } from 'react';

const useMember = ({ identifier, token } = { token: '', identifier: '' }) => {
  const [member, setMember] = useState({
    identifier,
    token,
    gender: 'N'
  });

  const loadMember = ({ identifier, token, gender = 'N' }) => {
    setMember({ identifier, token, gender });
  };

  /*
    Private Routes gonna redirect when identifier or token is null
  */
  const unloadMember = () => {
    setMember({ identifier: null, token: null });
  };

  const checkLoggedIn = () => {
    return Boolean(member.identifier && member.token);
  };

  return {
    member,
    loadMember,
    unloadMember
    //checkLoggedIn
  };
};

export default useMember;
