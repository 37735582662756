import { Routes, Route } from 'react-router-dom';

import { LandingPage } from './routes/LandingPage';
import { EndingPoll } from './routes/EndingPoll';
import { PrivateRoute } from './helpers/PrivateRoute';
import AppPoll from './routes/AppPoll';

const handleVisibilityChange = (e) => {
  if (document.visibilityState !== 'visible') {
    console.log('webpage not visible');
  }
};

export const App = () => {
  // useEffect(() => {
  //   addEventListener('visibilitychange', handleVisibilityChange);

  //   return () => {
  //     removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/app" element={<AppPoll />}/>
      </Route>
      <Route path="/ending" element={<EndingPoll />}/>
      <Route path="/" element={<LandingPage />}/>
    </Routes>
  );
};
