import { Fragment, forwardRef } from 'react';
import './Navbar.css';

import check from '../assets/white_check.svg';
import clsx from 'clsx';

const NavbarLogo = ({ data }) => {
  return (
    <img
      className='navbar__logo'
      src={data}
      // onError={() => {
      //   this.onerror = null;
      //   this.src = 'image.png';
      // }}
    />
  );
};

const NavbarLanguage = forwardRef(function NavBarLanguage ({ visible, data, flags, selected, handleShow, handleChangeLanguage } = { show: false, selected: 'es' }, ref) {
  return (
    <div className='navbar__language-wrapper' ref={ref}>
      <button onClick={() => handleShow()} className='navbar__language-btn'>
        <img src={data}/>
      </button>
      {
        <div className={clsx('fade-hide', visible && 'fade-show', 'navbar__languages-menu')}>
          {
            Object.values(flags).map((flag, i) => (
              <button
                className={clsx(selected === flag.code && 'active')}
                onClick={() => handleChangeLanguage(flag.code)}
                key={i}
              >
                <img src={flag.on}/>
                <span>{flag.name}</span>
                {selected === flag.code && <img className='navbar__languages-menu__checkicon' src={check}/>}
              </button>
            ))
          }
        </div>
      }
    </div>
  );
});

const Navbar = ({ children }) => {
  return (
    <div className='navbar'>
      {children}
    </div>
  );
};

export default Navbar;
export { NavbarLogo, NavbarLanguage };
