import { useEffect } from 'react';
/**
 * Hook that executes a callback when click outside of the passed ref
 */
function useOutsideCallback (ref, callback) {
  useEffect(() => {
    /**
     * Executes Callback if clicked on outside of element
     */
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export { useOutsideCallback };
export default useOutsideCallback;
