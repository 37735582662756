/* eslint-disable camelcase */
import { createContext } from 'react';
import PropTypes from 'prop-types';

import useMember from '../hooks/useMember';

export const AUTH_CONTEXT = createContext();

const AUTH_CONTEXT_PROVIDER = (props) => {
  const {
    member,
    loadMember,
    unloadMember
  } = useMember('', '');

  const handleLogout = () => {
    localStorage.removeItem('token');
    // This gonna make private routes navigate to the login page
    unloadMember();
  };

  return (
    <AUTH_CONTEXT.Provider
      value={{
        member,
        loadMember,
        unloadMember,
        handleLogout
      }}>
      {props.children}
    </AUTH_CONTEXT.Provider>
  );
};

AUTH_CONTEXT_PROVIDER.propTypes = {
  children: PropTypes.node.isRequired
};

export default AUTH_CONTEXT_PROVIDER;
