import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo_fork24.svg';
import useWindowSize from '../hooks/useWindowSize';
import Confetti from 'react-confetti';

export const EndingPoll = () => {
  const { t, i18n } = useTranslation();
  const { width, height } = useWindowSize();

  const navigate = useNavigate();
  const [timer, setTimer] = useState(20);

  const [pieces, setPieces] = useState(200);

  const { state } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setPieces(0);
    }, 3000);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    if (timer <= 0) {
      // quick-fix: we ensure that the state is reset
      window.location.replace('/');
    }
  }, [timer]);

  useEffect(() => {
    // you cannot be here if you don't have state... passed from AppPoll
    if (!state) {
      navigate('/');
    }
  }, [state]);

  return (
    <div className='special-page-container'>
      <Confetti
        numberOfPieces={pieces}
        width={width}
        height={height}
      />
      <img width="332" height="213" src={logo}/>
      <h1>{t('ending_poll_thanks')}</h1>
      <span>{t('redirecting')}</span>
      <span className='counter'>
        {timer}
      </span>
    </div>
  );
};
