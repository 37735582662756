import { useState } from 'react';

import { CONSTANTS_LEVEL, DAYTIME_POLL_PATH } from '../constants';

const { LEVEL_DATE, LEVEL_FOOD } = CONSTANTS_LEVEL;

const getLevelFoodPath = (dayTime) => {
  const levelFoodPath = [0];

  const getLevelFoodPathRecursive = (array, levelFoodPath) => {
    for (let i = 0; i < array.length; i++) {
      if (typeof array[i] === 'number') {
        if (array[i] === LEVEL_FOOD) {
          return levelFoodPath;
        } else {
          if (typeof levelFoodPath[levelFoodPath.length - 1] === 'object') {
            levelFoodPath[levelFoodPath.length - 1].i += 1;
          } else {
            levelFoodPath[levelFoodPath.length - 1] += 1;
          }
        }
      } else if (typeof array[i] === 'object') {
        levelFoodPath.push({ answer: 'yes', i: 0 });
        getLevelFoodPathRecursive(array[i].yes, levelFoodPath);
      }
    }
  };

  getLevelFoodPathRecursive(DAYTIME_POLL_PATH[dayTime], levelFoodPath);

  return levelFoodPath;
};

const useStages = (pollPath) => {
  const [stage, setStages] = useState({
    dayTime: 1,
    pollIndexes: [0],
    foodIDs: []
  });

  const selectDayTime = (dayTime, foodRecord = [], forceSelection = false) => {
    const dayTimeIndex = foodRecord.findIndex(record => record.dayTime === dayTime);
    let levelFoodPath = [0];

    if (foodRecord.length === 0 && forceSelection) {
      setStages(prevStage => ({
        ...prevStage,
        dayTime: 1,
        pollIndexes: [0]
      }));
    }

    const dayTimesAvailableBefore = foodRecord.some(record => record.dayTime > dayTime);

    if (dayTimesAvailableBefore) {
      setStages(prevStage => ({
        ...prevStage,
        dayTime,
        pollIndexes: [0]
      }));
    }

    if (dayTimeIndex === -1) {
      return;
    }

    // We cannot select daytimes if the daytime before is not completed
    if (forceSelection === false && dayTimeIndex !== 0 && !foodRecord[dayTimeIndex].completed) {
      return;
    }

    // we need to run over the array and make the path
    levelFoodPath = getLevelFoodPath(dayTime);

    setStages(prevStage => ({
      ...prevStage,
      dayTime,
      pollIndexes: levelFoodPath
    }));
  };

  const nextDayTime = (foodRecord = []) => {
    setStages(prevStage => ({
      ...prevStage,
      dayTime: prevStage.dayTime + 1
    }));
  };

  const nextLevel = (pollAnswer = null) => {
    const newStage = {
      ...stage
    };

    const { pollIndexes } = newStage;

    const newIndex = pollIndexes[pollIndexes.length - 1] + 1;

    // TODO: Prevent to go bypass the last index

    // We add 1 to previous index to access the new index
    if (typeof pollIndexes[pollIndexes.length - 1] === 'object') {
      pollIndexes[pollIndexes.length - 1].i += 1;

      if (pollAnswer === null) {
        setStages(prevStage => ({
          ...prevStage,
          pollIndexes: [...pollIndexes]
        }));
        return;
      }
    } else {
      pollIndexes[pollIndexes.length - 1] = newIndex;
    }

    const currentLevel = pollIndexes.reduce((previousValue, currentValue, currentIndex, array) => {
      if (typeof currentValue === 'object') {
        if (currentIndex !== array.length - 1) {
          return previousValue[currentValue.answer][currentValue.i];
        } else {
          return previousValue;
        }
      }
      return previousValue[currentValue];
    }, pollPath[stage.dayTime]);

    if (typeof currentLevel === 'object') {
      const answerByUser = pollAnswer ? 'yes' : 'no';

      setStages(prevStage => ({
        ...prevStage,
        pollIndexes: [...pollIndexes, ...[{ answer: answerByUser, i: 0 }]]
      }));
    } else { // We have reached the end of the poll
      // pollPath[stage.dayTime].length !== newIndex
      setStages(prevStage => ({
        ...prevStage,
        pollIndexes: [...pollIndexes]
      }));
    }
  };

  const backLevel = (pollAnswer = true) => {
    const newStage = {
      ...stage
    };

    const { pollIndexes } = newStage;

    if (pollIndexes.length === 0) {
      return;
    }

    const lastIndex = pollIndexes.length - 1;

    // We cannot go back if we are at the first index
    if (pollIndexes[0] === 0) {
      return;
    }

    if (typeof pollIndexes[lastIndex] === 'object') {
      if (pollIndexes[lastIndex].i === 0) {
        pollIndexes.pop();

        if (typeof pollIndexes[lastIndex - 1] === 'object') {
          pollIndexes[lastIndex - 1].i -= 1;
        } else {
          pollIndexes[lastIndex - 1] -= 1;
        }
      } else {
        pollIndexes[lastIndex].i -= 1;
      }
    } else {
      if (pollIndexes[lastIndex] === 0) {
        pollIndexes.pop();
      } else {
        pollIndexes[lastIndex] -= 1;
      }
    }

    setStages(prevStage => ({
      ...prevStage,
      pollIndexes: [...pollIndexes]
    }));
  };

  const resetLevel = () => {
    setStages(prevState => ({
      ...prevState,
      pollIndexes: [0]
    }));
  };

  const selectFoodID = (foodID, categoryFoodID) => {
    if (categoryFoodID) {
      setStages(prevState => ({
        ...prevState,
        foodIDs: [...prevState.foodIDs, categoryFoodID, foodID]
      }));
    } else {
      stage.foodIDs.push(foodID);
      setStages(prevStage => ({
        ...prevStage
      }));
    }
  };

  const unselectFoodID = () => {
    setStages(prevStage => ({
      ...prevStage,
      foodIDs: 0
    }));
  };

  const popFoodID = () => {
    stage.foodIDs.pop();
    setStages(prevStage => ({
      ...prevStage
    }));
  };

  const resetFoodsIDs = () => {
    stage.foodIDs = [];
    setStages(prevStage => ({
      ...prevStage
    }));
  };

  const setNewFoodIDsPath = (newFoodsIDs) => {
    setStages(prevStage => ({
      ...prevStage,
      foodIDs: [...newFoodsIDs]
    }));
  };

  return {
    stage,
    selectDayTime,
    nextDayTime,
    nextLevel,
    resetLevel,
    resetFoodsIDs,
    backLevel,
    selectFoodID,
    popFoodID,
    unselectFoodID,
    setNewFoodIDsPath
  };
};

export default useStages;
