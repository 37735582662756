import { useState, useCallback } from 'react';

function useFetch (url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback((options) => {
    setLoading(true);
    setData(null);
    setError(null);

    console.log('fetchData', options);
    console.log('url', url);

    fetch(url, options)
      .then(async res => {
        setLoading(false);
        const result = await res.json();
        const newData = { ...result, statusNumber: res.status, options };
        setData(newData);
      })
      .catch(_err => {
        setLoading(false);
        setError('An error occurred. Awkward..');
      });
  }, [url]);

  return { data, loading, error, fetchData };
}

export default useFetch;
