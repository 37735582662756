import { createRef, useEffect, useState, useContext, Fragment } from 'react';
import i18next from 'i18next';

import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';

import checkIfImageExists from '../utils/image';
import Modal, { ModalDesc, ModalTitle, ModalSubTitle, ModalFoodItem } from '../components/Modal';
import Navbar, { NavbarLogo, NavbarLanguage } from '../components/Navbar';

import logoSVG from '../assets/logo_fork24.svg';
import hamSVG from '../assets/ham_menu.svg';
import check from '../assets/check_big_blue.svg';

import subSVG from '../assets/sub.svg';
import sumSVG from '../assets/sum.svg';
import closeWhiteSVG from '../assets/close_x_white.svg';
import closeSVG from '../assets/remove_x.svg';

import { flags } from '../i18n';

import DatePicker, { registerLocale } from 'react-datepicker';
// import { format } from 'date-fns'
import { ca, gl, eu, es } from 'date-fns/locale';

import useFetch from '../hooks/useFetch';
import { useShow } from '../hooks/useShow';
import useOutsideCallback from '../hooks/useOutsideCallback';
import useFoodRecord from '../hooks/useFoodRecord';
import useStages from '../hooks/useStages';

import { genderTransMap } from '../utils/gender';

import {
  CONSTANTS_DAYTIME,
  CONSTANTS_LEVEL,
  DAYTIME_POLL_PATH
} from '../constants';

import MyMealsRecord from '../components/MyMealsRecord';
import FoodPhasesMenu from '../components/FoodPhasesMenu';
import TimePicker from '../components/TimePicker';
import FoodsList from '../components/FoodsList';
import SquareButton from '../components/ForwardButton';
import { PopupAlert } from '../components/PopupAlert';

import { AUTH_CONTEXT } from '../context/AuthProvider';

const {
  DAYTIME_BREAKFAST,
  DAYTIME_MIDMORNING,
  DAYTIME_LUNCH,
  DAYTIME_AFTERNOON_SNACK,
  DAYTIME_DINNER
} = CONSTANTS_DAYTIME;

const {
  // LEVEL_NULL,
  LEVEL_DATE,
  LEVEL_FOOD,
  LEVEL_WAKEUP_TIME,
  LEVEL_ASK_ATE,
  LEVEL_CONFIRM_ATE,
  LEVEL_ASK_ATE_WITH_SOMEONE,
  LEVEL_ASK_INPUT_TEXT,
  // LEVEL_BACK_YES,
  LEVEL_CONFIRM_FOOD,
  LEVEL_CONFIRM_FOOD_BETWEEN_MEALS,
  LEVEL_ASK_NORMAL_FOOD
} = CONSTANTS_LEVEL;

const answersTypes = {
  [LEVEL_WAKEUP_TIME]: 1, // Wake-up Time
  [LEVEL_DATE]: 2, // Meal time
  [LEVEL_ASK_NORMAL_FOOD]: 3, // Normal Food
  [LEVEL_ASK_ATE_WITH_SOMEONE]: 4 // Company
};

registerLocale('es', es);
registerLocale('ca', ca);
registerLocale('gl', gl);
registerLocale('eu', eu);

const { API_URL } = process.env;

const AppPoll = () => {
  const { t, i18n } = useTranslation();

  const { show, handleClose, handleTouch } = useShow(true);
  const modalRemoveItem = useShow(false);
  const modalRemoveDay = useShow(false);
  const modalLogout = useShow(false);
  const menuLanguages = useShow(false);
  const menuMobile = useShow(false);
  const modalEditMeal = useShow(false);

  const portionPicker = useShow(false);
  const { member } = useContext(AUTH_CONTEXT);

  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    data,
    // loading,
    // error,
    fetchData
  } = useFetch(API_URL + 'public/foods/list' + '/' + member.identifier);

  const fetchSaveFood = useFetch(`${API_URL}public/${i18n.language}/answers/food/save`);
  const fetchRemoveFood = useFetch(`${API_URL}public/${i18n.language}/answers/food/delete`);
  const fetchRemoveMeal = useFetch(`${API_URL}public/${i18n.language}/answers/delete`);
  const fetchSaveAnswer = useFetch(`${API_URL}public/${i18n.language}/answers/other/save`);
  const fetchUpdateAnswers = useFetch(`${API_URL}public/${i18n.language}/answers/other/update`);
  const fetchFinishPoll = useFetch(`${API_URL}public/${i18n.language}/forms/finish`);

  const {
    stage,
    selectDayTime,
    nextDayTime,
    nextLevel,
    backLevel,
    selectFoodID,
    resetLevel,
    resetFoodsIDs,
    popFoodID,
    setNewFoodIDsPath
  } = useStages(DAYTIME_POLL_PATH);

  const {
    foodRecords,
    updateDayTimeDate,
    // skipDayTimeRecord,
    completeDayTimeRecord,
    addFoodRecord,
    editFoodRecord,
    removeFoodRecord,
    removeDayTime,
    // getFoodRecord,
    loadFoodRecords
  } = useFoodRecord();

  const [foods, setFoods] = useState([]);

  const [pollAnswers, setPollAnswers] = useState({
    [DAYTIME_BREAKFAST]: {
      wakeup_time: null,
      date: null,
      eat_wsomeone: false
    },
    [DAYTIME_MIDMORNING]: {
      date: null,
      eat_wsomeone: false
    },
    [DAYTIME_LUNCH]: {
      date: null,
      eat_wsomeone: false
    },
    [DAYTIME_AFTERNOON_SNACK]: {
      date: null,
      eat_wsomeone: false
    },
    [DAYTIME_DINNER]: {
      date: null,
      eat_wsomeone: false
    }
  });

  // const [wakeupTime, setWakeupTime] = useState('00:00');
  // const [date, setDate] = useState('00:00');
  const [inputText, setInputText] = useState('');

  // const [enableTimeButton, setEnableTimeButton] = useState(false);

  const [popupMessage, setPopupMessage] = useState('');
  const [portion, setPortion] = useState(null);
  const [foodEditing, setFoodEditing] = useState(false);
  const [editDayTime, setEditDayTime] = useState(1);

  const [isAnswersEdited, setIsAnswersEdited] = useState(false);

  const [removeItem, setRemoveItem] = useState({});

  const portionsDictionary = {
    1: `${t('portion_very_small')}`,
    2: `${t('portion_small')}`,
    3: `${t('portion_medium')}`,
    4: `${t('portion_big')}`,
    5: `${t('portion_very_big')}`
  };

  const [imagesPortionUrl, setImagesPortionUrl] = useState({
    s: '/locales/assets/foods/food_small.png',
    m: '/locales/assets/foods/food_medium.png',
    l: '/locales/assets/foods/food_big.png'
  });

  useEffect(() => {
    const imagePortionSmall = `/locales/assets/foods/${stage.foodIDs[stage.foodIDs.length - 1]}s.jpg`;
    const imagePortionMedium = `/locales/assets/foods/${stage.foodIDs[stage.foodIDs.length - 1]}m.jpg`;
    const imagePortionLarge = `/locales/assets/foods/${stage.foodIDs[stage.foodIDs.length - 1]}l.jpg`;

    checkIfImageExists(imagePortionSmall, (err, exists) => {
      if (err) {
        console.log('error checking if imagePortionSmall exists');
      }

      if (exists) {
        setImagesPortionUrl(prevImages => ({
          ...prevImages,
          s: imagePortionSmall
        }));
      } else {
        setImagesPortionUrl({
          s: '/locales/assets/foods/food_small.png',
          m: '/locales/assets/foods/food_medium.png',
          l: '/locales/assets/foods/food_big.png'
        });
      }
    });

    checkIfImageExists(imagePortionMedium, (err, exists) => {
      if (err) {
        console.log('error checking if imagePortionMedium exists');
      }

      if (exists) {
        setImagesPortionUrl(prevImages => ({
          ...prevImages,
          m: imagePortionMedium
        }));
      } else {
        setImagesPortionUrl({
          s: '/locales/assets/foods/food_small.png',
          m: '/locales/assets/foods/food_medium.png',
          l: '/locales/assets/foods/food_big.png'
        });
      }
    });

    checkIfImageExists(imagePortionLarge, (err, exists) => {
      if (err) {
        console.log('error checking if imagePortionLarge exists');
      }

      if (exists) {
        setImagesPortionUrl(prevImages => ({
          ...prevImages,
          l: imagePortionLarge
        }));
      } else {
        setImagesPortionUrl({
          s: '/locales/assets/foods/food_small.png',
          m: '/locales/assets/foods/food_medium.png',
          l: '/locales/assets/foods/food_big.png'
        });
      }
    });
  }, [stage.foodIDs.length, stage.foodIDs]);

  useEffect(() => {
    fetchData({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token')
      }
    });
  }, []);

  useEffect(() => {
    if (state) {
      const { answersFood, answersOther } = state;

      if (answersFood && answersFood.length !== 0) {
        const newFoodsRecord = loadFoodRecords(answersFood, t);
        const lastDayTime = answersFood.sort((a, b) => a.meal - b.meal)[answersFood.length - 1].meal;
        selectDayTime(lastDayTime, newFoodsRecord);
      }

      if (answersOther && answersOther.length !== 0) {
        const newPollAnswers = answersOther.reduce((prevValue, currentVal, i, array) => {
          const { type, meal, value } = currentVal;

          if (type === answersTypes[LEVEL_WAKEUP_TIME]) {
            return {
              ...prevValue,
              [meal]: {
                ...prevValue[meal],
                wakeup_time: value
              }
            };
          } else if (type === answersTypes[LEVEL_DATE]) {
            return {
              ...prevValue,
              [meal]: {
                ...prevValue[meal],
                date: value
              }
            };
          } else if (type === answersTypes[LEVEL_ASK_INPUT_TEXT]) {
            setInputText(value);
          } else if (type === answersTypes[LEVEL_ASK_ATE_WITH_SOMEONE]) {
            return {
              ...prevValue,
              [meal]: {
                ...prevValue[meal],
                eat_wsomeone: value === 'true' ? true : false // eslint-disable-line
              }
            };
          }
          return prevValue;
        }, pollAnswers);

        setPollAnswers({ ...newPollAnswers });
      }
    }
  }, [state]);

  useEffect(() => {
    const addFoodTranslations = async () => {
      if (data) {
        if (data.status === 'error') {
          return;
        }
        setFoods(data.foods);

        const newFoodsBundles = {
          ca: {},
          es: {},
          gl: {},
          eu: {}
        };

        const addFoodResource = (food) => {
          if (food.children) {
            addFoodsResources(food.children);
          }

          newFoodsBundles.ca['food_' + food.id] = food.name.ca;
          newFoodsBundles.es['food_' + food.id] = food.name.es;
          newFoodsBundles.gl['food_' + food.id] = food.name.gl;
          newFoodsBundles.eu['food_' + food.id] = food.name.eu;
          // i18n.addResource('es', 'translation', 'food_' + food.id, food.name.es);
          // i18n.addResource('gl', 'translation', 'food_' + food.id, food.name.gl);
          // i18n.addResource('eu', 'translation', 'food_' + food.id, food.name.eu);
          // i18n.addResource('ca', 'translation', 'food_' + food.id, food.name.ca);
        };

        const addFoodsResources = (myFoods) => {
          myFoods.forEach(addFoodResource);
        };

        addFoodsResources(data.foods);

        i18n.addResourceBundle('es', 'translation', newFoodsBundles.es, true, false);
        i18n.addResourceBundle('gl', 'translation', newFoodsBundles.gl, true, false);
        i18n.addResourceBundle('eu', 'translation', newFoodsBundles.eu, true, false);
        i18n.addResourceBundle('ca', 'translation', newFoodsBundles.ca, true, false);
      }
    };
    addFoodTranslations();
  }, [data]);

  const foodPhases = [
    {
      name: t('breakfast'),
      phase: DAYTIME_BREAKFAST
    },
    {
      name: t('midmorning'),
      phase: DAYTIME_MIDMORNING
    },
    {
      name: t('lunch'),
      phase: DAYTIME_LUNCH
    },
    {
      name: t('afternoon_snack'),
      phase: DAYTIME_AFTERNOON_SNACK
    },
    {
      name: t('dinner'),
      phase: DAYTIME_DINNER
    }
  ];

  const languagesMenuRef = createRef();

  useOutsideCallback(languagesMenuRef, () => {
    menuLanguages.handleClose();
  });

  const handleCloseModal = () => {
    handleClose();
  };

  const handlePollEatWithSomeone = (dayTime, answer) => {
    pollAnswers[dayTime].eat_wsomeone = answer;
    setIsAnswersEdited(true);
    setPollAnswers({ ...pollAnswers });
  };

  // date format 00:00
  const handlePollDateChange = (dayTime, date) => {
    const hour = parseInt(date.split(':')[0]);
    const minutes = parseInt(date.split(':')[1]);

    if (dayTime === DAYTIME_BREAKFAST && date < pollAnswers[dayTime].wakeup_time) {
      setPopupMessage(`Introduce una hora después de la que te levantaste (${pollAnswers[dayTime].wakeup_time})`);
      return;
    }

    // ? Where is DAYTIME_MIDMORNING?

    if (dayTime === DAYTIME_BREAKFAST && (!(hour >= 4 && hour <= 15) || (hour === 15 && minutes !== 0))) {
      setPopupMessage('La horas permitidas es desde las 04:00 hasta las 15:00');
      return;
    } else if (dayTime === DAYTIME_LUNCH && (!(hour >= 11 && hour <= 17) || (hour === 17 && minutes !== 0))) {
      setPopupMessage('La horas permitidas es desde las 11:00 hasta las 17:00');
      return;
    } else if (dayTime === DAYTIME_AFTERNOON_SNACK && (!(hour >= 15 && hour <= 21) || (hour === 21 && minutes !== 0))) {
      setPopupMessage('La horas permitidas es desde las 15:00 hasta las 21:00');
      return;
    } else if (dayTime === DAYTIME_DINNER && (!(hour >= 18 || hour <= 4) || (hour === 4 && minutes !== 0))) {
      setPopupMessage('La horas permitidas es desde las 18:00 hasta las 04:00');
      return;
    }

    pollAnswers[dayTime].date = date;
    setIsAnswersEdited(true);
    setPollAnswers({ ...pollAnswers });
  };

  const handleChangeWakeUpTime = (dayTime, date) => {
    pollAnswers[dayTime].wakeup_time = date;
    setIsAnswersEdited(true);
    setPollAnswers({ ...pollAnswers });
  };

  const handleClickMobileMenu = () => {
    menuMobile.handleTouch();
  };

  const handleChangeDayTime = (dayTime, foodRecords) => {
    setFoodEditing(false);
    resetFoodsIDs();
    setPortion(null);
    selectDayTime(dayTime, foodRecords);
  };

  const handleNextLevel = (answer = null, value = '', stageLevel = -1) => {
    if (value) {
      // const foodRecord = getFoodRecord(stage.dayTime);

      if (stageLevel !== -1 && answersTypes[stageLevel] !== undefined) {
        fetchSaveAnswer.fetchData({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('token')
          },
          body: JSON.stringify({
            identifier: member.identifier,
            answer: {
              // date: foodRecord.date || '00:00',
              meal: parseInt(stage.dayTime),
              type: answersTypes[stageLevel],
              value
            }
          })
        });
      }
    } else {
      nextLevel(answer);

      const pollPath = getPollLevel();

      if (pollPath?.yes && pollPath.yes[0] === LEVEL_FOOD) {
        updateDayTimeDate(stage.dayTime, pollAnswers[stage.dayTime].date);
      }
    }
  };

  useEffect(() => {
    const fetchSaveAnswerResult = async () => {
      if (fetchSaveAnswer.data) {
        if (fetchSaveAnswer.data.status === 'error') {
          setPopupMessage(t('error_answer_saved'));
          return;
        }

        const body = await JSON.parse(fetchSaveAnswer.data.options.body);

        let answer = null;

        if (body.answer.value === 'true') {
          answer = true;
        } else if (body.answer.value === 'false') {
          answer = false;
        }

        nextLevel(answer);

        const pollPath = getPollLevel();

        // we only show date when level is food
        if (pollPath?.yes && pollPath.yes[0] === LEVEL_FOOD) {
          updateDayTimeDate(stage.dayTime, pollAnswers[stage.dayTime].date);
        }
      }
    };
    fetchSaveAnswerResult();
  }, [fetchSaveAnswer.data]);

  useEffect(() => {
    if (fetchSaveAnswer.error) {
      setPopupMessage(t('error_answer_saved'));
    }
  }, [fetchSaveAnswer.error]);

  const handleClickBack = (answer = null) => {
    backLevel(answer);
  };

  // const handleSkipSection = () => {
  //   if (stage.dayTime === DAYTIME_DINNER) {
  //     navigate('/ending');
  //     resetLevel();
  //   } else {
  //     const newFoodRecord = skipDayTimeRecord(stage.dayTime);
  //     skipDayTime(stage.dayTime + 1, newFoodRecord);
  //     resetLevel();
  //   }
  // };

  const handleNextSection = () => {
    completeDayTimeRecord(stage.dayTime);

    if (stage.dayTime === DAYTIME_DINNER) {
      fetchFinishPoll.fetchData({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token')
        },
        body: JSON.stringify({
          identifier: member.identifier
        })
      });
    } else {
      // ! workaround, we reset these states because they are gonna be reused on the next levels...
      // but since we need to be able to edit them, they're gonna be separated states
      // setDate('00:00');
      // setWakeupTime('00:00');
      setPortion(null);
      setInputText('');

      nextDayTime();
      resetLevel();
    }
  };

  useEffect(() => {
    if (fetchFinishPoll.data) {
      if (fetchFinishPoll.data.status === 'error') {
        setPopupMessage(t('error_poll_finished'));
        return;
      }
      navigate('/ending', {
        state: {
          ending: true
        }
      });
      resetLevel();
    }
  }, [fetchFinishPoll.data]);

  useEffect(() => {
    if (fetchFinishPoll.error) {
      setPopupMessage(t('error_poll_finished'));
    }
  }, [fetchFinishPoll.error]);

  const handleBackPortionPicker = () => {
    setPortion(null);
    setFoodEditing(false);

    if (stage.foodIDs.length === 0) {
      backLevel(null);
    } else {
      popFoodID();
    }
  };

  // const handleSubPortion = () => {
  //   if (portion > 0) {
  //     setPortion(portion - 1);
  //   }
  // };

  // const handleAddPortion = () => {
  //   if (portion < 2) {
  //     setPortion(portion + 1);
  //   }
  // };

  const handleClickSaveFood = (foodEditing = false) => {
    const foodID = stage.foodIDs[stage.foodIDs.length - 1];
    // const foodRecord = getFoodRecord(stage.dayTime);

    fetchSaveFood.fetchData({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token')
      },
      body: JSON.stringify({
        identifier: member.identifier,
        answer: {
          food_id: foodID,
          size: portion,
          meal: stage.dayTime
          // time: foodRecord.date || '00:00'
        }
      })
    });
  };

  useEffect(() => {
    const fetchSaveFoodResult = async () => {
      if (fetchSaveFood.data) {
        if (fetchSaveFood.data.status === 'error') {
          setPopupMessage(t('error_food_added'));
          return;
        }

        const body = await JSON.parse(fetchSaveFood.data.options.body);

        // const compoundName = stage.foodIDs.slice(1, stage.foodIDs.length).map(foodID => t('food_' + foodID)).join(' | ');

        const food = {
          id: body.answer.food_id,
          // name: compoundName,
          pathIDs: [...stage.foodIDs],
          getName: function (pathIDs = this.pathIDs) {
            return pathIDs.slice(1, pathIDs.length).map(foodID => t('food_' + foodID)).join(' | ') || t('food_' + this.id);
          },
          portion: body.answer.size,
          nodeRef: createRef(null)
        };

        const isFoodAdded = addFoodRecord(stage.dayTime, food);

        if (isFoodAdded) {
          setPopupMessage(t('food_added'));
          nextLevel();
          resetFoodsIDs();
          setPortion(null);
        } else if (foodEditing) {
          editFoodRecord(stage.dayTime, food);
          setFoodEditing(false);
          resetFoodsIDs();
          setPortion(null);
          setPopupMessage(t('food_portion_edited'));
        } else {
          setPopupMessage(t('error_food_already_added'));
        }
      }
    };
    fetchSaveFoodResult();
  }, [fetchSaveFood.data]);

  useEffect(() => {
    if (fetchSaveFood.error) {
      setPopupMessage(t('error_food_added'));
    }
  }, [fetchSaveFood.error]);

  const handleRemoveFood = (dayTime, food) => {
    modalRemoveItem.handleShow();
    setRemoveItem({ dayTime, ...food });
  };

  const handleEditFood = (dayTime, food) => {
    // change daytime
    if (dayTime !== stage.dayTime) {
      selectDayTime(dayTime, foodRecords);
    }

    setFoodEditing(true);
    setPortion(food.portion);
    setNewFoodIDsPath(food.pathIDs);
  };

  const handleCloseRemoveModal = (actionRemove) => {
    if (actionRemove) {
      fetchRemoveFood.fetchData({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token')
        },
        body: JSON.stringify({
          identifier: member.identifier,
          answer: {
            food_id: removeItem.id,
            meal: removeItem.dayTime
          }
        })
      });
    } else {
      modalRemoveItem.handleClose();
      setRemoveItem({});
    }
  };

  useEffect(() => {
    const fetchRemoveFoodResult = async () => {
      if (fetchRemoveFood.data) {
        if (fetchRemoveFood.data.status !== 'success') {
          setPopupMessage(t('error_food_removed'));
          return;
        }
        // const body = await JSON.parse(fetchSaveFood.data.options.body);

        const removed = removeFoodRecord(removeItem.dayTime, removeItem.id);

        if (removed) {
          setPopupMessage(t('food_removed'));
        }

        modalRemoveItem.handleClose();
        setRemoveItem({});
      }
    };
    fetchRemoveFoodResult();
  }, [fetchRemoveFood.data]);

  useEffect(() => {
    if (fetchRemoveFood.error) {
      setPopupMessage(t('error_food_removed'));
    }
  }, [fetchRemoveFood.error]);

  const handleCloseRemoveDayModal = (actionRemove) => {
    if (actionRemove) {
      fetchRemoveMeal.fetchData({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token')
        },
        body: JSON.stringify({
          identifier: member.identifier,
          meal: removeItem.dayTime
        })
      });
    } else {
      modalRemoveDay.handleClose();
      setRemoveItem({});
    }
  };

  useEffect(() => {
    if (fetchRemoveMeal.data) {
      if (fetchRemoveMeal.data.status !== 'success') {
        setPopupMessage(t('error_daytime_removed'));
        return;
      }
      const removed = removeDayTime(removeItem.dayTime);

      if (removed) {
        const lastDayTime = foodRecords.length >= 1 && foodRecords.sort((a, b) => a.dayTime - b.dayTime)[foodRecords.length - 1].dayTime;
        setPopupMessage(t('daytime_removed'));
        selectDayTime((foodRecords.length !== 0 && lastDayTime) || DAYTIME_BREAKFAST, foodRecords, true);
      };

      modalRemoveDay.handleClose();
      setRemoveItem({});
    }
  }, [fetchRemoveMeal.data]);

  useEffect(() => {
    if (fetchRemoveMeal.error) {
      setPopupMessage(t('error_daytime_removed'));
    }
  }, [fetchRemoveMeal.error]);

  const handleRemoveDayTime = (dayTime) => {
    modalRemoveDay.handleShow();
    setRemoveItem({ dayTime });
  };

  const handleClickEditMeal = (dayTime) => {
    setEditDayTime(dayTime);
    modalEditMeal.handleShow();
  };

  const handleSaveEditedAnswers = ({ meal, pollAnswers }) => {
    const answerTypes = {
      wakeup_time: 1,
      date: 2,
      eat_wsomeone: 4
    };

    const answers = Object.entries(pollAnswers).map(answer => {
      let value = answer[1];

      if (answer[0] === 'eat_wsomeone') {
        value = answer[1] === true ? 'true' : 'false';
      }

      return {
        type: answerTypes[answer[0]],
        value
      };
    });

    fetchUpdateAnswers.fetchData({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token')
      },
      body: JSON.stringify({
        identifier: member.identifier,
        meal,
        answers
      })
    });
  };

  useEffect(() => {
    if (fetchUpdateAnswers.data) {
      console.log('fetchUpdateAnswers data', fetchUpdateAnswers.data);
      if (fetchUpdateAnswers.data.status === 'error') {
        setPopupMessage(t('error_answer_saved'));
      } else {
        setPopupMessage(t('answers_updated'));
      }
    }
  }, [fetchUpdateAnswers.data]);

  useEffect(() => {
    if (fetchUpdateAnswers.error) {
      setPopupMessage(t('error_answer_saved'));
    }
  }, [fetchUpdateAnswers.error]);

  // 06:00 to 11:59 - morning
  // 12:00 to 14:59 - noon
  // 15:00 to 20:59 - afternoon
  // 21:00 to 00:59 - night
  // 01:00 to 05:59 - late night
  const getDayTimeText = (date) => {
    if (!date) {
      return '';
    }

    const hour = parseInt(date.split(':')[0]);

    if (hour >= 6 && hour < 12) {
      return t('morning');
    } else if (hour >= 12 && hour < 15) {
      return t('noon');
    } else if (hour >= 15 && hour < 21) {
      return t('afternoon');
    } else if (hour >= 21 && hour < 24) {
      return t('night');
    } else if (hour >= 0 && hour < 6) {
      return t('early_morning');
    }
  };

  const dayTimeHumanized = getDayTimeText(pollAnswers[stage.dayTime].wakeup_time);
  const dayTimeDateHumanized = getDayTimeText(pollAnswers[stage.dayTime].date);

  /*
  * example 16:00 pm to 4
  */
  const hoursTo12 = (hours) => {
    const hour = parseInt(hours.split(':')[0]);
    return hour > 12 ? hour - 12 : hour;
  };

  const componentsLevels = {
    [LEVEL_DATE]: (
      <div className='wakeup-container'>
        <span className='poll-question poll-question--big'>
          <Trans i18nKey={`lv_${LEVEL_DATE}_title_${stage.dayTime}`}>
            ¿A qué hora <span className='color-blue'>desayunaste</span> ayer?
          </Trans>
        </span>
        <TimePicker
          date={pollAnswers[stage.dayTime].date || '00:00'}
          visibleDropdown={true}
          onChangeDate={(date) => {
            handlePollDateChange(stage.dayTime, date);
          }}
        />
        {
          pollAnswers[stage.dayTime].date && (
            <span className='wakeup-daytime-text'>
              <Trans i18nKey="date_humanized" t={t} daytime={dayTimeDateHumanized} >
                {{ hours: hoursTo12(pollAnswers[stage.dayTime].date) }} y {{ minutes: parseInt(pollAnswers[stage.dayTime].date.split(':')[1]) }} minutos de la {{ daytime: dayTimeDateHumanized }}
              </Trans>
            </span>
          )
        }
        <div className='btn-back-wrapper'>
          <SquareButton
            position='left'
            enableButton={true}
            onClickNext={() => handleClickBack()}
          />
        </div>
        <div className='btn-forward-wrapper'>
          <SquareButton
            enableButton={pollAnswers[stage.dayTime].date && !fetchSaveAnswer.loading}
            onClickNext={() => handleNextLevel(null, pollAnswers[stage.dayTime].date, LEVEL_DATE)}
          />
        </div>
      </div>
    ),
    [LEVEL_CONFIRM_FOOD]: (
      <div className='wakeup-container'>
        <span className='poll-question poll-question--big'>
          <Trans i18nKey={`lv_${LEVEL_CONFIRM_FOOD}_title_${stage.dayTime}`} t={t}>
            Piensa en lo que <span className='color-blue'>comiste</span> y <span className='color-blue'>bebiste</span> ayer en el <span className='color-blue'>desayuno</span> y elige una de las siguientes opciones:
          </Trans>
        </span>
        <div className='poll-choose-answer poll-choose-answer--long'>
          <button onClick={() => handleClickBack()}>
            {t('add_new_food')}
          </button>
          <button
            onClick={() => stage.dayTime === DAYTIME_DINNER ? handleNextLevel() : handleNextSection()}
            disabled={fetchSaveAnswer.loading}
          >
            {t(`lv_${stage.dayTime}_i_didnt_eat_anything`)}
          </button>
        </div>
      </div>
    ),
    [LEVEL_FOOD]: (
      <div className='food-selector'>
        <FoodsList
          foods={foods}
          selectedFoods={stage.foodIDs}
          onSkipMeal={() => stage.dayTime === DAYTIME_DINNER ? handleNextLevel() : handleNextSection()}
          onBack={() => handleBackPortionPicker()}
          pollQuestionText={
            <Trans i18nKey={`lv_${LEVEL_FOOD}_title_${stage.dayTime}`} t={t}>
              ¿Cuál de estos <span className='color-blue'>alimentos</span> y <span className='color-blue'>bebidas</span> tomaste ayer para <span className='color-blue'>desayunar</span>?
            </Trans>
          }
          meal={stage.dayTime}
          renderPortionPicker={(foodIDs) => {
            return (
              <Fragment>
                {
                  portionPicker.show && <div className='foods-portion-background hide-desktop'>
                    <div>
                      <span className='foods-portion-mobile__title'>Selecciona una ración...</span>
                      <div className='foods-portion-mobile'>
                        <button
                          id="portion1"
                          className={clsx(portion === 1 && 'selected-svg selected-mark')}
                          onClick={() => { setPortion(1); portionPicker.handleClose(); } }
                        >
                          <object type='image/svg+xml' data={subSVG} />
                        </button>
                        <button
                          id="portion2"
                          className={clsx(portion === 2 && 'selected selected-mark')}
                          style={{ backgroundImage: `url(${imagesPortionUrl.s})` }}
                          onClick={() => { setPortion(2); portionPicker.handleClose(); }}
                        >
                        </button>
                        <button
                          id="portion3"
                          className={clsx(portion === 3 && 'selected selected-mark')}
                          style={{ backgroundImage: `url(${imagesPortionUrl.m})` }}
                          onClick={() => { setPortion(3); portionPicker.handleClose(); }}
                        >
                        </button>
                        <button
                          id="portion4"
                          className={clsx(portion === 4 && 'selected selected-mark')}
                          style={{ backgroundImage: `url(${imagesPortionUrl.l})` }}
                          onClick={() => { setPortion(4); portionPicker.handleClose(); }}
                        >
                        </button>
                        <button
                          id="portion5"
                          className={clsx(portion === 5 && 'selected-svg selected-mark')}
                          onClick={() => { setPortion(5); portionPicker.handleClose(); }}
                        >
                          <object type='image/svg+xml' data={sumSVG} />
                        </button>
                      </div>
                      <span className='foods-portion-mobile__portion-name'>{portion && t('mobile_food_portion_' + portion)}</span>
                      <div id="controls" className="food-portion-mobile--controls">
                        <a href="#portion1"></a>
                        <a href="#portion2"></a>
                        <a href="#portion3"></a>
                        <a href="#portion4"></a>
                        <a href="#portion5"></a>
                      </div>
                    </div>
                  </div>
                }
                <div className='foods-selection-mobile hide-desktop'>
                  <div className='foods-selection-mobile__header'>
                    <div>
                      <img src={`/locales/assets/foods/${stage.foodIDs[stage.foodIDs.length - 1]}.png`} alt='food' />
                    </div>
                    <span>{t('food_' + stage.foodIDs[stage.foodIDs.length - 2]) + ' | ' + t('food_' + stage.foodIDs[stage.foodIDs.length - 1])}</span>
                  </div>
                  <div className='foods-selection-mobile__buttons'>
                    <button
                      type='button'
                      onClick={() => portionPicker.handleTouch()}
                    >
                      Selecciona una ración
                    </button>
                    <button
                      type='button'
                      disabled={portion === null}
                      onClick={() => handleClickSaveFood(foodEditing)}
                    >
                      {
                        (foodEditing && t('edit_food')) || t('save_food')
                      }
                    </button>
                  </div>
                </div>
                <div className='foods-portion-picker'>
                  <div>
                    <span>{t('food_' + stage.foodIDs[stage.foodIDs.length - 2]) + ' | ' + t('food_' + stage.foodIDs[stage.foodIDs.length - 1])}</span>
                    <div className='foods-portion-picker__tools'>
                      <button className={clsx(portion === 1 && 'selected-svg selected-mark')} onClick={() => setPortion(1)}>
                        <object type='image/svg+xml' data={subSVG} />
                        <span>Menos cantidad que la foto de la derecha</span>
                      </button>
                      <button
                        className={clsx(portion === 2 && 'selected selected-mark')}
                        style={{ backgroundImage: `url(${imagesPortionUrl.s})` }}
                        onClick={() => setPortion(2)}
                      >
                      </button>
                      <button
                        className={clsx(portion === 3 && 'selected selected-mark')}
                        style={{ backgroundImage: `url(${imagesPortionUrl.m})` }}
                        onClick={() => setPortion(3)}
                      >
                        </button>
                      <button
                        className={clsx(portion === 4 && 'selected selected-mark')}
                        style={{ backgroundImage: `url(${imagesPortionUrl.l})` }}
                        onClick={() => setPortion(4)}
                      >
                      </button>
                      <button className={clsx(portion === 5 && 'selected-svg selected-mark')} onClick={() => setPortion(5)}>
                        <object type='image/svg+xml' data={sumSVG} />
                        <span>Más cantidad que la foto de la izquierda</span>
                      </button>
                    </div>
                    <button
                      onClick={() => handleClickSaveFood(foodEditing)}
                      disabled={portion === null}
                      type='button'
                      className='btn btn-primary'
                    >
                      {
                        (foodEditing && t('edit_food')) || t('save_food')
                      }
                    </button>
                  </div>
                </div>
              </Fragment>
            );
          }
          }
          onSelectFood={(foodID, categoryID = 0) => {
            selectFoodID(foodID, categoryID);
          }}
          lang={i18n.resolvedLanguage}
        />
      </div>
    ),
    [LEVEL_WAKEUP_TIME]: (
      <div className='wakeup-container'>
        <span className='poll-question poll-question--big'>
          <Trans i18nKey={`lv_${LEVEL_WAKEUP_TIME}_title_${stage.dayTime}`} t={t}>
            ¿A qué hora te <span className='color-blue'>levantaste</span> ayer?
          </Trans>
        </span>
        <TimePicker
          date={pollAnswers[stage.dayTime].wakeup_time || '00:00'}
          visibleDropdown={true}
          onChangeDate={(date) => {
            handleChangeWakeUpTime(stage.dayTime, date);
            // setEnableTimeButton(true);
          }}
        />
        {
          pollAnswers[stage.dayTime].wakeup_time && (
            <span className='wakeup-daytime-text'>
              <Trans i18nKey="date_humanized" t={t} daytime={dayTimeHumanized}>
                {{ hours: hoursTo12(pollAnswers[stage.dayTime].wakeup_time) }} y {{ minutes: parseInt(pollAnswers[stage.dayTime].wakeup_time.split(':')[1]) }} minutos de la {{ daytime: dayTimeHumanized }}
              </Trans>
            </span>
          )
        }
        {/* <div className='btn-back-wrapper'>
          <SquareButton
            position='left'
            enableButton={enableTimeButton}
            onClickNext={() => handleClickNext()}
          />
        </div> */}
        <div className='btn-forward-wrapper'>
          <SquareButton
            enableButton={pollAnswers[stage.dayTime].wakeup_time && !fetchSaveAnswer.loading}
            onClickNext={() => handleNextLevel(null, pollAnswers[stage.dayTime].wakeup_time, LEVEL_WAKEUP_TIME)}
          />
        </div>
      </div>
    ),
    [LEVEL_ASK_ATE]: (
      <div className='wakeup-container'>
        <span className='poll-question poll-question--big'>
          <Trans i18nKey={`lv_${LEVEL_ASK_ATE}_title_${stage.dayTime}`} t={t}>
            ¿<span className='color-blue'>Tomaste</span> algo durante la <span className='color-blue'>mañana</span> de ayer? (por ejemplo: en el recreo o patio, entre las clases, antes de la comida de medio día, etc.)
          </Trans>
        </span>
        <div className='poll-choose-answer'>
          <button onClick={() => handleNextLevel(true)}>
            {t('yes')}
          </button>
          <button onClick={() => handleNextLevel(false)}>{t('no')}</button>
        </div>
      </div>
    ),
    [LEVEL_CONFIRM_ATE]: (
      <div className='wakeup-container'>
        <span className='poll-question poll-question--big'>
        <Trans i18nKey={`lv_${LEVEL_CONFIRM_ATE}_title_${stage.dayTime}_${genderTransMap[member.gender]}`} t={t}>
          ¿Estás segure de que ayer no <span className='color-blue'>comiste</span> ni <span className='color-blue'>bebiste</span> absolutamente nada para <span className='color-blue'>desayunar</span>?
        </Trans>
        </span>
        <div className='poll-choose-answer poll-choose-answer--long'>
          <button onClick={() => handleNextSection()}>
            <Trans i18nKey={`confirm_ate_yes_${genderTransMap[member.gender]}`} t={t}>
              Estoy segure de que <span className='color-blue'>NO tomé nada</span>
            </Trans>
          </button>
          <button onClick={() => handleClickBack(false)}>
            <Trans i18nKey={`confirm_ate_no`} t={t}>
              Acabo de recordar que <span className='color-blue'>SÍ tomé algo</span>, vamos a añadirlo
            </Trans>
          </button>
        </div>
      </div>
    ),
    [LEVEL_ASK_ATE_WITH_SOMEONE]: (
      <div className='wakeup-container'>
        <span className='poll-question poll-question--big'>
        <Trans i18nKey={`lv_${LEVEL_ASK_ATE_WITH_SOMEONE}_title_${stage.dayTime}`} t={t}>
          ¿<span className='color-blue'>Comiste</span> al mediodía con alguien?
        </Trans>
        </span>
        <div className='poll-choose-answer'>
          <button onClick={() => handleNextLevel(true, 'true', LEVEL_ASK_ATE_WITH_SOMEONE)}>
            {t('yes')}
          </button>
          <button onClick={() => handleNextLevel(false, 'false', LEVEL_ASK_ATE_WITH_SOMEONE)}>
            {t('no')}
          </button>
        </div>
      </div>
    ),
    [LEVEL_ASK_INPUT_TEXT]: (
      <div className='wakeup-container'>
        <div className='ask-input-container'>
          <span className='poll-question poll-question--big'>
            {t(`lv_${LEVEL_ASK_INPUT_TEXT}_title_${stage.dayTime}`)}
          </span>
          <span className='poll-description'>
            {t(`lv_${LEVEL_ASK_INPUT_TEXT}_desc_${stage.dayTime}`)}
            {/* (Campo de texto de 30 caracteres) */}
          </span>
          <input onChange={(e) => setInputText(e.target.value)} value={inputText} maxLength={70} className='input-text' type='textarea' placeholder='Escribe tu respuesta...'></input>
        </div>
        {/* <div className='btn-back-wrapper'>
          <SquareButton
            position='left'
            enableButton={true}
            onClickNext={() => handleClickBack()}
          />
        </div> */}
        <div className='btn-forward-wrapper'>
          <SquareButton
            enableButton={inputText !== '' && inputText.length > 3 && !fetchSaveAnswer.loading}
            onClickNext={() => handleNextLevel(null, inputText, LEVEL_ASK_INPUT_TEXT)}
          />
        </div>
      </div>
    ),
    [LEVEL_CONFIRM_FOOD_BETWEEN_MEALS]: (
      <div className='wakeup-container'>
        <span className='poll-question poll-question--big'>
        <Trans i18nKey={`lv_${LEVEL_CONFIRM_FOOD_BETWEEN_MEALS}_title_${stage.dayTime}_${genderTransMap[member.gender]}`} t={t}>
          ¿Estás seguro que no has <span className='color-blue'>comido</span> algo más entre horas? (lo puedes añadir a la comida del día que más se acerque)
        </Trans>
        </span>
        <div className='poll-choose-answer poll-choose-answer--long'>
          <button onClick={() => handleNextSection()}>
            <Trans i18nKey={`confirm_food_between_meals_yes_${genderTransMap[member.gender]}`} t={t}>
              Estoy segure de que <span className='color-blue'>NO tomé nada más entre horas</span>
            </Trans>
          </button>
          <button onClick={() => handleClickBack(false)}>
            <Trans i18nKey={`confirm_food_between_meals_no`} t={t}>
              Acabo de recordar que <span className='color-blue'>SÍ tomé algo más</span>, vamos a añadirlo
            </Trans>
          </button>
        </div>
      </div>
    ),
    [LEVEL_ASK_NORMAL_FOOD]: (
      <div className='wakeup-container'>
        <span className='poll-question poll-question--big'>
        <Trans i18nKey={`lv_${LEVEL_ASK_NORMAL_FOOD}_title_${stage.dayTime}`} t={t}>
          Para terminar, ¿Crees que ayer comiste <span className='color-blue'>diferente</span> a lo <span className='color-blue'>habitual</span>?
        </Trans>
        </span>
        <div className='poll-choose-answer'>
          <button onClick={() => handleNextLevel(true, 'true', LEVEL_ASK_NORMAL_FOOD)}>
            {t('yes')}
          </button>
          <button onClick={() => handleNextLevel(false, 'false', LEVEL_ASK_NORMAL_FOOD)}>
            {t('no')}
          </button>
        </div>
      </div>
    )
  };

  const getPollLevel = () => {
    const pollPath = { ...DAYTIME_POLL_PATH[stage.dayTime] };

    const newStage = { ...stage };
    const { pollIndexes } = newStage;

    const levelID = pollIndexes.reduce((previousValue, currentValue, currentIndex, array) => {
      if (typeof currentValue === 'object') {
        if (typeof previousValue === 'number') {
          return previousValue;
        } else {
          return previousValue[currentValue.answer][currentValue.i];
        }
      }
      return previousValue[currentValue];
    }, pollPath);

    return levelID;
  };

  const handleChangeLanguage = (lang) => i18n.changeLanguage(lang);

  const pollLevel = getPollLevel();

  return (
    <div className='app-wrapper'>
      <div className={clsx('fade-hide', modalEditMeal.show && 'fade-show', 'modal-edit-meal--background')} />
      <div className={clsx('fade-hide', modalEditMeal.show && 'fade-show', 'modal-edit-meal')}>
        <div className='modal-edit-meal__header'>
          <span>{foodPhases[editDayTime - 1].name || 'Cena'}</span>
          <button onClick={() => { setIsAnswersEdited(false); modalEditMeal.handleTouch(); }} type='button'>
            <object type='image/svg+xml' data={closeSVG}/>
          </button>
        </div>
        <div className='modal-edit-meal__body'>
            {/* pollAnswers[editDayTime].wakeup_time !== undefined && (
              <Fragment>
                <span className='poll-question poll-question--small'>
                {
                  t('lv_3_title_1')
                }
                </span>
              <TimePicker
                type={'small'}
                date={pollAnswers[editDayTime].wakeup_time || '00:00'}
                visibleDropdown={true}
                onChangeDate={(date) => {
                  handleChangeWakeUpTime(editDayTime, date);
                  // setEnableTimeButton(true);
                }}
              />
            </Fragment>
            )
          /* <span className='poll-question poll-question--small'>
            {
              t('what_hour')
            }
          </span>
          {/* <TimePicker
            type={'small'}
            date={pollAnswers[editDayTime].date || '00:00'}
            visibleDropdown={true}
            onChangeDate={(date) => {
              handlePollDateChange(editDayTime, date);
              // setEnableTimeButton(true);
            }}
          /> */}
          <span className='poll-question poll-question--small'>
            <Trans i18nKey={`lv_6_title_${foodPhases[editDayTime - 1].phase}`} t={t}>
                ¿<span>Desayunaste</span> con alguien?
            </Trans>
          </span>
          <div className='modal-edit-meal__body__checkboxes'>
            <span>
              <input
                checked={pollAnswers[editDayTime].eat_wsomeone === true}
                onChange={(event) => handlePollEatWithSomeone(editDayTime, true)}
                type='checkbox'
              />
                {t('yes')}
            </span>
            <span>
              <input
                checked={pollAnswers[editDayTime].eat_wsomeone === false}
                onChange={(event) => handlePollEatWithSomeone(editDayTime, false)}
                type='checkbox'
              />
                {t('no')}
            </span>
          </div>
          <button
            type='button'
            disabled={
              (isAnswersEdited === false || fetchUpdateAnswers.loading) ||
              (editDayTime === DAYTIME_BREAKFAST &&
              (pollAnswers[editDayTime].date < pollAnswers[editDayTime].wakeup_time ||
              pollAnswers[editDayTime].wakeup_time > pollAnswers[editDayTime].date))
            }
            onClick={() => handleSaveEditedAnswers({
              meal: editDayTime,
              pollAnswers: pollAnswers[editDayTime]
            })}
            className='btn btn-primary'
          >
            {t('update_changes')}
          </button>
        </div>
      </div>
      <div className='container container--background container--less-padding'>
        <PopupAlert
          trigger={popupMessage !== ''}
          onEnd={() => setPopupMessage('')}
        >
          {popupMessage}
        </PopupAlert>
        <Modal visible={modalLogout.show}>
          <ModalTitle>{t('are_you_sure_to_exit', { context: genderTransMap[member.gender] })}</ModalTitle>
          <div className='modal-content__buttons'>
            <button
              type='button'
              onClick={() => modalLogout.handleClose()}
              className='btn btn-secondary'
            >
              {t('no')}
            </button>
            <button
              type='button'
              onClick={() => window.location.reload()}
              className='btn btn-primary'
            >
              {t('yes')}
            </button>
          </div>
        </Modal>
        <Modal
          visible={show}
          size='large'
        >
          <div className='modal-content__inner-texts'>
            <ModalTitle>{t('welcome')}</ModalTitle>
            <ModalSubTitle>{t('welcome_1')}</ModalSubTitle>
            <ul>
              <li>
                <ModalDesc>
                  <Trans i18nKey="welcome_2" t={t}>
                    Es una encuesta <strong>anónima</strong>: no hay respuestas buenas o malas, <strong>no es un examen.</strong>
                  </Trans>
                </ModalDesc>
              </li>
              <li>
                <ModalDesc>
                  <Trans i18nKey="welcome_3" t={t}>
                    Te preguntaremos sobre <strong>TODO LO QUE COMISTE Y BEBISTE AYER</strong>, desde que te levantaste hasta que te fuiste a dormir.
                  </Trans>
                </ModalDesc>
              </li>
              <li>
                <ModalDesc>
                  <Trans i18nKey="welcome_4" t={t}>
                    Las preguntas hacen referencia a <strong>LAS COMIDAS</strong> que <strong>AYER</strong> hiciste.
                  </Trans>
                </ModalDesc>
              </li>
              <li>
                <ModalDesc>
                  <Trans i18nKey="welcome_5" t={t}>
                    Haz clic encima de las fotos de los alimentos y bebidas que comiste y bebiste ayer que irán apareciendo en tu pantalla.
                  </Trans>
                </ModalDesc>
              </li>
              <li>
                <ModalDesc>
                  <Trans i18nKey="welcome_6" t={t}>
                      Si tienes cualquier pregunta levanta la mano y <strong>el equipo PASOS o el profesorado te ayudará.</strong>
                  </Trans>
                </ModalDesc>
              </li>
            </ul>
            <ModalFoodItem>{t('welcome_7')}</ModalFoodItem>
          </div>
          <button
            type='button'
            onClick={() => handleCloseModal()}
            className='btn btn-primary btn--modal'
          >{t('understood')}</button>
        </Modal>
        <Modal
          visible={modalRemoveItem.show}
        >
          <ModalTitle>{t('attention')}</ModalTitle>
          <ModalDesc>{t('confirm_delete_food')}</ModalDesc>
          <ModalFoodItem>{removeItem.name && `${removeItem.name} ${portionsDictionary[removeItem.portion]}`}</ModalFoodItem>
          <div className='modal-content__buttons'>
            <button
              type='button'
              onClick={() => handleCloseRemoveModal(false)}
              className='btn btn-secondary'
              disabled={fetchRemoveFood.loading}
            >
              {
                t('no')
              }
            </button>
            <button
              type='button'
              onClick={() => handleCloseRemoveModal(true)}
              className='btn btn-primary'
              disabled={fetchRemoveFood.loading}
            >
              {
                t('yes')
              }
            </button>
          </div>
        </Modal>
        <Modal visible={modalRemoveDay.show}>
          <ModalTitle>{t('attention')}</ModalTitle>
          <ModalDesc>{t('are-you-sure-you-didnt-eat', { context: genderTransMap[member.gender] })}</ModalDesc>
          <div className='modal-content__buttons'>
            <button
              type='button'
              onClick={() => handleCloseRemoveDayModal(false)}
              className='btn btn-secondary'
            >
              {t('no')}
            </button>
            <button
              type='button'
              onClick={() => handleCloseRemoveDayModal(true)}
              className='btn btn-primary'
            >
              {t('yes')}
            </button>
          </div>
        </Modal>
        <Navbar>
          <NavbarLogo data={logoSVG} />
          <button
            type='button'
            onClick={() => handleClickMobileMenu()}
            className={clsx('hide-desktop navbar__ham-btn')}
          >
            <object type='image/svg+xml' width='29' height='18' data={hamSVG} />
          </button>
          <div className='navbar__tools show-desktop'>
            <NavbarLanguage
              ref={languagesMenuRef}
              data={flags[i18n.resolvedLanguage].on || flags.es.on}
              selected={i18n.resolvedLanguage}
              visible={menuLanguages.show}
              handleShow={() => menuLanguages.handleTouch()}
              handleChangeLanguage={(lang) => handleChangeLanguage(lang)}
              flags={flags}
            />
            <button
              onClick={() => handleTouch()}
              className='navbar-help'
            >
              <span>
                {t('help')}
              </span>
            </button>
            <button
              onClick={() => modalLogout.handleShow()}
              type='button'
              className='btn btn-secondary'
            >
              {t('exit')}
            </button>
          </div>
        </Navbar>
        <div className={clsx('fade-hide', menuMobile.show && 'fade-show', 'navbar__mobile-menu')}>
          <header>
            <object type='image/svg+xml' data={logoSVG} />
            <button onClick={() => menuMobile.handleTouch()}>
              <object type='image/svg+xml' width='20' height='20' data={closeWhiteSVG} />
            </button>
          </header>
          <button
            onClick={() => { handleTouch(); menuMobile.handleTouch(); } }
            type='button'
            className='navbar__mobile-menu__help'>
            <span>{t('help')}</span>
          </button>
          <div className={clsx('navbar__languages-menu navbar__languages-menu--mobile')}>
            {
              Object.values(flags).map((flag, i) => (
                <button
                  className={clsx(i18n.resolvedLanguage === flag.code && 'active')}
                  onClick={() => i18n.changeLanguage(flag.code)}
                  key={i}
                >
                  <object type='image/svg+xml' data={flag.on}/>
                  <span>{flag.name}</span>
                  <object type='image/svg+xml' data={check}/>
                </button>
              ))
            }
          </div>
          <button
            type='button'
            className='btn btn-secondary btn-secondary--white'
            onClick={() => { menuMobile.handleTouch(); modalLogout.handleShow(); }}
          >
            <span>{t('exit')}</span>
          </button>
        </div>
        {/* <span className='show-desktop food-phase-title'>Selecciona una comida</span> */}
        <FoodPhasesMenu
          handleClick={(dayTime) => handleChangeDayTime(dayTime, foodRecords)}
          selectedPhase={stage.dayTime}
          foodRecord={foodRecords}
          phases={foodPhases}
        />
        {
          componentsLevels[pollLevel]
        }
      </div>
      <MyMealsRecord
        items={foodRecords}
        pollAnswers={pollAnswers}
        dayTime={stage.dayTime}
        disableEditButtons={fetchRemoveFood.loading || pollLevel !== LEVEL_FOOD}
        disableRemoveButtons={fetchRemoveFood.loading}
        onEditFood={(dayTime, food) => handleEditFood(dayTime, food)}
        onRemoveFood={(dayTime, food) => handleRemoveFood(dayTime, food)}
        onRemoveDayTime={(dayTime) => handleRemoveDayTime(dayTime)}
        portionsDictionary={portionsDictionary}
        onClickFinish={() => handleNextSection()}
        onClickEdit={(dayTime) => handleClickEditMeal(dayTime)}
      />
    </div>
  );
};

export default AppPoll;
