import clsx from 'clsx';
import forwardSVG from '../assets/forward.svg';
import backSVG from '../assets/back.svg';
import { useTranslation } from 'react-i18next';

const SquareButton = ({ enableButton, onClickNext, position, size }) => {
  const { t } = useTranslation();

  let classNameActive = 'btn-square--active';
  let classNameSize = 'btn-square--small';

  if (position === 'left') {
    classNameActive = 'btn-square--active-left';
  }
  if (size === 'small') {
    classNameSize = 'btn-square--small';
  }

  return (
    <button
      disabled={!enableButton}
      onClick={() => onClickNext()}
      className={clsx('btn btn-square',
        enableButton && classNameActive,
        size && classNameSize)}
    >
      {position === 'right' && <span className='show-desktop'>{t('next')}</span>}
      <img src={position === 'left' ? backSVG : forwardSVG}/>
      {position === 'left' && <span className='show-desktop'>{t('forward')}</span>}
    </button>
  );
};

SquareButton.defaultProps = {
  position: 'right'
};

export default SquareButton;
