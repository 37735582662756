import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import clsx from 'clsx';

import getUnixTime from 'date-fns/getUnixTime';
import { ca, gl, eu, es } from 'date-fns/locale';

import useFetch from '../hooks/useFetch';

import logo from '../assets/logo_fork24.svg';

import { flags } from '../i18n';

import arrowSVG from '../assets/flecha.svg';

import 'react-datepicker/dist/react-datepicker.css';

import { AUTH_CONTEXT } from '../context/AuthProvider';

registerLocale('es', es);
registerLocale('ca', ca);
registerLocale('gl', gl);
registerLocale('eu', eu);

const currentDate = new Date();
const minDate = new Date(currentDate.getFullYear() - 19, currentDate.getMonth(), 1);
const maxDate = new Date(currentDate.getFullYear() - 7, currentDate.getMonth() + 1, 0);

const renderDayContents = (day, date) => {
  if (date < minDate || date > maxDate) {
    return <span></span>;
  }
  return <span>{date.getDate()}</span>;
};

export const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const { API_URL } = process.env;

  const { loadMember } = useContext(AUTH_CONTEXT);

  const navigate = useNavigate();

  const { fetchData, data, loading, error } = useFetch(API_URL + 'public/' + i18n.resolvedLanguage + '/forms/start');

  const [birthDate, setBirthDate] = useState(null);

  const [gender, setGender] = useState('DEFAULT');
  const [identifier, setIdentifier] = useState('');
  const [formErrorMsg, setFormErrorMsg] = useState('');

  const handleChangeDate = (date, type, birthDate) => {
    setFormErrorMsg('');

    const initialBirthDate = new Date(currentDate.getFullYear() - 10, currentDate.getMonth(), 1);

    if (!date) {
      return;
    }

    if (type === 'day') {
      const newDate = new Date(birthDate?.getFullYear() || initialBirthDate.getFullYear(), date.getMonth(), date.getDate());
      setBirthDate(newDate);
    } else if (type === 'month') {
      const newDate = new Date(birthDate?.getFullYear() || initialBirthDate.getFullYear(), date.getMonth(), birthDate?.getDate() || initialBirthDate.getDate());
      setBirthDate(newDate);
    } else {
      const newDate = new Date(date.getFullYear(), birthDate?.getMonth() || initialBirthDate.getMonth(), birthDate?.getDate() || initialBirthDate.getDate());
      setBirthDate(newDate);
    };
  };

  useEffect(() => {
    if (error) {
      setFormErrorMsg(t('unexpected_error'));
    }
  }, [error]);

  useEffect(() => {
    const fetchLoginResult = async () => {
      if (data) {
        if (data.status === 'error') {
          setFormErrorMsg(data.message);
          return;
        }

        const body = await JSON.parse(data.options.body);

        if (data.token) {
          loadMember({ identifier: body.identifier, token: data.token.token, gender });
          localStorage.setItem('token', data.token.token);
        }

        if (data.code === 'continue') {
          navigate('/app', {
            state: {
              answersFood: data.answers_food,
              answersOther: data.answers_other
            }
          });
        } else if (data.code === 'disabled') {
          setFormErrorMsg(t('disabled_poll'));
        } else if (data.code === 'completed') {
          setFormErrorMsg(t('completed_poll'));
        } else {
          navigate('/app');
        }
      }
    };

    fetchLoginResult();
  }, [data]);

  const validateForm = ({ enableMessages, identifier } = { enableMessages: true }) => {
    let errMessage = t('unexpected_error');
    let formIsValid = true;

    console.log('identifier', identifier.length < 10);

    if (gender === '' || gender === 'DEFAULT') {
      errMessage = t('gender_not_selected');
      formIsValid = false;
    } else if (checkBirthDateOutRange({ enableMessages, birthDate })) {
      errMessage = t('birth_out_of_range');
      formIsValid = false;
    } else if (!identifier || (identifier.length !== 10 && identifier.length !== 11)) {
      errMessage = t('check_identifier');
      formIsValid = false;
    }

    if (enableMessages && !formIsValid) {
      setFormErrorMsg(errMessage);
    }
    return formIsValid;
  };

  const handleSubmitForm = async (e, identifier) => {
    e.preventDefault();
    // loadMember({ identifier: '1234567890', token: localStorage.getItem('token') });
    // navigate('/app');

    if (validateForm({ identifier })) {
      await fetchData({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*'
        },
        body: JSON.stringify({
          birth_date: getUnixTime(birthDate),
          gender,
          identifier,
          language: i18n.resolvedLanguage
        })
      });
    }
  };

  const handleChangeGender = (e, gender) => {
    // select.options[select.selectedIndex].value
    setFormErrorMsg('');

    if (gender) {
      setGender(gender);
      return;
    }
    setGender(e.target.value);
  };

  const hideErrors = () => {
    setFormErrorMsg('');
  };

  const handleChangeIdentifier = (e) => {
    setFormErrorMsg('');
    setIdentifier(e.target.value);
  };

  const checkBirthDateOutRange = ({ enableMessages, birthDate }) => {
    let isOutOfRange = false;
    let newDate = birthDate;

    if (birthDate === null) {
      return true;
    }

    if (birthDate < minDate) {
      newDate = new Date(birthDate.getFullYear(), minDate.getMonth(), birthDate.getDate());
      isOutOfRange = true;
    } else if (birthDate > maxDate) {
      newDate = new Date(birthDate.getFullYear(), maxDate.getMonth(), birthDate.getDate());
      isOutOfRange = true;
    }

    if (enableMessages) {
      setBirthDate(newDate);
    }
    return isOutOfRange;
  };

  return (
    <div className='container container--background'>
      <div className='header'>
        <img className='header__logo' src={logo}></img>
        <img className='header__language' src={flags.es.on}></img>
        <div className='header__languages-selector'>
          {
            Object.values(flags).map((flag, i) => (
              <button type='button'
                className={clsx(i18n.resolvedLanguage === flag.code && 'active')}
                onClick={() => i18n.changeLanguage(flag.code)}
                key={i}
              >
                <span className='tooltip'>{flag.name}</span>
                <img src={flag.on} alt={flag.name}/>
              </button>
            ))
          }
        </div>
      </div>
      <div className='landing-secondary-container'>
        <span></span>
        <span className='landing-secondary-container__title'>{t('food_register')}</span>
      </div>
      <div className='landing-wrapper-box'>
        <div className='landing-box'>
          <span className='title title--food-register hide-desktop'>
            {t('food_register')}
          </span>
          <span className='title show-desktop'>
            {t('login')}
          </span>
          <span className='desc'>{t('login_text')}</span>
          <form onSubmit={(e) => handleSubmitForm(e, identifier)} className='form-landing'>
            <div className='form-group form-group--mb25'>
              <label>
                {t('date_of_birth')}
              </label>
              <div className='date-pickers'>
                <div className='date-picker-wrapper date-picker--small'>
                  <DatePicker
                    placeholderText="Días"
                    dateFormat="dd"
                    renderCustomHeader={({ date }) => <div></div>}
                    selected={birthDate}
                    // renderDayContents={renderDayContents}
                    popperClassName="date-popper-custom"
                    enableTabLoop={false}
                    onChange={(date) => handleChangeDate(date, 'day', birthDate)}
                    locale={i18n.resolvedLanguage}
                  />
                  <object className='select-arrow select-arrow--pos-mid' type='image/svg+xml' data={arrowSVG}></object>
                </div>
                <div className='date-picker-wrapper date-picker--medium'>
                  <DatePicker
                    placeholderText="Meses"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    autoComplete='on'
                    dateFormat="MMMM"
                    renderCustomHeader={({ date }) => <div></div>}
                    selected={birthDate}
                    showTwoColumnMonthYearPicker
                    popperClassName="date-popper-custom-month"
                    className='date-picker__month'
                    onChange={(date) => handleChangeDate(date, 'month', birthDate)}
                    enableTabLoop={false}
                    locale={i18n.resolvedLanguage}
                  />
                  <object className='select-arrow select-arrow--pos-mid' type='image/svg+xml' data={arrowSVG}></object>
                </div>
                <div className='date-picker-wrapper date-picker--small'>
                  <DatePicker
                    placeholderText="Años"
                    selected={birthDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(date) => handleChangeDate(date, 'year', birthDate)}
                    showYearPicker
                    popperClassName="date-popper-custom"
                    enableTabLoop={false}
                    dateFormat="yyyy"
                    locale={i18n.resolvedLanguage}
                  />
                  <object className='select-arrow select-arrow--pos-mid' type='image/svg+xml' data={arrowSVG}></object>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label>
                {t('gender')}
              </label>
              {/* <button onClick={() => handleModal('gender')} type='button' className='form-control'>Selecciona género</button> */}
              <div className='show-desktop form-group__inputs'>
                <label><input onChange={(e) => handleChangeGender(e, 'M')} checked={gender === 'M'} className='round-checkbox' type="checkbox"></input> {t('male')}</label>
                <label><input onChange={(e) => handleChangeGender(e, 'F')} checked={gender === 'F'} className='round-checkbox' type="checkbox"></input> {t('fem')}</label>
                <label><input onChange={(e) => handleChangeGender(e, 'N')} checked={gender === 'N'} className='round-checkbox' type="checkbox"></input> {t('no_binary')}</label>
              </div>
              <select
                onFocus={() => hideErrors()}
                onChange={(e) => handleChangeGender(e)}
                value={gender}
                className='form-control custom-select hide-desktop'
              >
                <option value='DEFAULT' disabled>
                  {t('select_a_gender')}
                </option>
                <option value='M'>{t('male')}</option>
                <option value='F'>{t('fem')}</option>
                <option value='N'>{t('no_binary')}</option>
              </select>
              <object className='select-arrow hide-desktop' type='image/svg+xml' data={arrowSVG}></object>
            </div>
            <div className='form-group form-group--mb35'>
              <label>{t('identifier')}
                <input
                  required
                  onChange={(e) => handleChangeIdentifier(e)}
                  value={identifier}
                  minLength={10}
                  maxLength={11}
                  placeholder={t('identifier_example')}
                  type='text'
                  className='form-control'
                />
              </label>
            </div>
            {formErrorMsg && <span className='error-message error-message--margins'>{formErrorMsg}</span>}
            <button
              disabled={!validateForm({ enableMessages: false, identifier }) || loading}
              onMouseOver={() => validateForm({ identifier, enableMessages: true })}
              type='submit'
              className='btn btn-primary'
            >
              {t('start')}
            </button>
          </form>
        </div>
      </div>
      {/* <Lottie className='login-lottie' animationData={animationData} loop={true} /> */}
      <div className='background-gradient'/>
    </div>
  );
};
