import clsx from 'clsx';
import './FoodPhasesMenu.css';

import checkSVG from '../assets/ok_white.svg';

const FoodPhasesMenu = ({ handleClick, selectedPhase, phases, foodRecord }) => {
  const items = [...foodRecord];

  return (
    <div className='food-phases-menu'>
    {
      phases.map(({ name, phase }) => {
        const record = items.find(record => record.dayTime === phase);

        const sectionCompleted = record && record.completed;

        return (
          <button
            key={phase}
            className={
              clsx(
                'food-phases-menu__button',
                sectionCompleted && 'food-phases-menu__button--completed',
                selectedPhase === phase && 'food-phases-menu__button--selected'
              )}
            onClick={() => handleClick(phase)}
          >
            <span>{name}</span>
            {sectionCompleted && <span className='food-phases-menu__button--completed--icon'>
              <object type='image/svg+xml' data={checkSVG} />
            </span>}
          </button>
        );
      })
    }
    </div>
  );
};

export default FoodPhasesMenu;
