import { useState, createRef } from 'react';

// [
//   {
//     dayTime: 1,
//     date: '2022-08-08T07:38:40.433Z', // new Date
//     nodeRef: createRef(),
//     food: [
//       {
//         id: 23,
//         name: t('food_' + id) // we define translations on the fly when receive categories
//         portion: 1
//       }
//     ]
//   }
// ]

const useFoodRecord = () => {
  const [foodRecords, setFoodRecords] = useState([]);

  const addFoodRecord = (dayTime, food) => {
    const dayTimeIndex = foodRecords.findIndex(record => record.dayTime === dayTime);

    if (dayTimeIndex !== -1) {
      if (foodRecords[dayTimeIndex].food.findIndex(record => record.id === food.id) !== -1) {
        return false;
      }

      foodRecords[dayTimeIndex] = {
        ...foodRecords[dayTimeIndex],
        food: [...foodRecords[dayTimeIndex].food, food]
      };

      setFoodRecords((prevFoodRecords) => [...prevFoodRecords]);
    } else {
      setFoodRecords((prevFoodRecords) => [...prevFoodRecords, {
        dayTime,
        date: null,
        food: [food]
      }]);
    }
    return true;
  };

  const editFoodRecord = (dayTime, food) => {
    const dayTimeIndex = foodRecords.findIndex(record => record.dayTime === dayTime);

    if (dayTimeIndex !== -1) {
      const foodIndex = foodRecords[dayTimeIndex].food.findIndex(record => record.id === food.id);

      if (foodIndex !== -1) {
        foodRecords[dayTimeIndex].food[foodIndex] = food;
        setFoodRecords((prevFoodRecords) => [...prevFoodRecords]);
      }
    }
  };

  const getFoodRecord = (dayTime) => {
    const dayTimeIndex = foodRecords.findIndex(record => record.dayTime === dayTime);

    if (dayTimeIndex !== -1) {
      return foodRecords[dayTimeIndex];
    }

    return null;
  };

  const removeFoodRecord = (dayTime, foodID) => {
    const dayTimeIndex = foodRecords.findIndex(record => record.dayTime === dayTime);

    if (dayTimeIndex !== -1) {
      const foodIndex = foodRecords[dayTimeIndex].food.findIndex(record => record.id === foodID);

      if (foodIndex !== -1) {
        foodRecords[dayTimeIndex].food.splice(foodIndex, 1);
        setFoodRecords([...foodRecords]);
        return true;
      }
    }
    return false;
  };

  const removeDayTime = (dayTime) => {
    const dayTimeIndex = foodRecords.findIndex(record => record.dayTime === dayTime);

    if (dayTimeIndex !== -1) {
      foodRecords.splice(dayTimeIndex, 1);
      setFoodRecords([...foodRecords]);
      return true;
    }
    return false;
  };

  const updateDayTimeDate = (dayTime, newDate) => {
    const dayTimeIndex = foodRecords.findIndex(record => record.dayTime === dayTime);

    if (dayTimeIndex !== -1) {
      foodRecords[dayTimeIndex].date = newDate;
      setFoodRecords(foodRecords);
    } else {
      setFoodRecords([...foodRecords, { dayTime, date: newDate, food: [] }]);
    }
  };

  const skipDayTimeRecord = (dayTime) => {
    const dayTimeIndex = foodRecords.findIndex(record => record.dayTime === dayTime);

    if (dayTimeIndex !== -1) {
      return;
    }

    const newFoodRecord = [...foodRecords, { dayTime, date: null, food: [], completed: true }];
    setFoodRecords([...newFoodRecord]);

    return newFoodRecord;
  };

  const completeDayTimeRecord = (dayTime) => {
    const dayTimeIndex = foodRecords.findIndex(record => record.dayTime === dayTime);

    if (dayTimeIndex !== -1) {
      foodRecords[dayTimeIndex].completed = true;
      setFoodRecords(foodRecords);
    } else {
      setFoodRecords([...foodRecords, { dayTime, date: null, food: [], completed: true }]);
    }
  };

  const loadFoodRecords = (answersFood, t) => {
    const storedAnswersFood = answersFood.reduce((prevValue, currentVal, i, array) => {
      const index = prevValue.findIndex(record => record.dayTime === currentVal.meal);
      // const ancestorsLength = currentVal.ancestors.length;

      const ancestorsIDs = currentVal.ancestors.map(ancestor => ancestor.id);

      const food = {
        id: currentVal.food_id,
        pathIDs: [...ancestorsIDs] || [currentVal.food_id],
        getName: function (pathIDs = this.pathIDs) {
          return pathIDs.slice(1, pathIDs.length).map(foodID => t('food_' + foodID)).join(' | ') || t('food_' + this.id);
        },
        portion: currentVal.size,
        nodeRef: createRef(null)
      };

      if (index !== -1) {
        prevValue[index].food.push(food);
        return prevValue;
      } else {
        return [
          ...prevValue, {
            dayTime: currentVal.meal,
            date: null,
            completed: true,
            food: [food]
          }
        ];
      }
    }, []);

    //     dayTime: 1,
    //     date: '2022-08-08T07:38:40.433Z', // new Date
    //     food: [

    setFoodRecords([...storedAnswersFood]);

    return storedAnswersFood;
  };

  return {
    foodRecords,
    addFoodRecord,
    editFoodRecord,
    getFoodRecord,
    removeFoodRecord,
    removeDayTime,
    updateDayTimeDate,
    skipDayTimeRecord,
    completeDayTimeRecord,
    loadFoodRecords
  };
};

export default useFoodRecord;
