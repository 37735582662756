import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import './PopupAlert.css';

export const PopupAlert = (props) => {
  useEffect(() => {
    if (props.trigger) {
      setTimeout(() => {
        props.onEnd();
      }, 3000);
    }
  }, [props.trigger]);

  return (
    <div className={clsx('popup-box', props.trigger && 'popup-box--show')}>
      <span>{props.children}</span>
    </div>
  );
};

PopupAlert.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.bool.isRequired,
  onEnd: PropTypes.func.isRequired
};
