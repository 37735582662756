import { useContext } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { AUTH_CONTEXT } from '../context/AuthProvider';

import PropTypes from 'prop-types';

const PrivateRoute = ({ children }) => {
  const { member } = useContext(AUTH_CONTEXT);

  // const navigate = useNavigate();

  console.log('the memver info is', member);

  if (!member.token && !member.identifier) {
    return <Navigate to="/" replace />;
  }

  return <Outlet/> || children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node
};

export { PrivateRoute };
