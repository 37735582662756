
const CONSTANTS_DAYTIME = {
  DAYTIME_BREAKFAST: 1,
  DAYTIME_MIDMORNING: 2,
  DAYTIME_LUNCH: 3,
  DAYTIME_AFTERNOON_SNACK: 4,
  DAYTIME_DINNER: 5
};

const {
  DAYTIME_BREAKFAST,
  DAYTIME_MIDMORNING,
  DAYTIME_LUNCH,
  DAYTIME_AFTERNOON_SNACK,
  DAYTIME_DINNER
} = CONSTANTS_DAYTIME;

const CONSTANTS_LEVEL = {
  LEVEL_NULL: 0,
  LEVEL_DATE: 1,
  LEVEL_FOOD: 2,
  LEVEL_WAKEUP_TIME: 3,
  LEVEL_ASK_ATE: 4,
  LEVEL_CONFIRM_ATE: 5,
  LEVEL_ASK_ATE_WITH_SOMEONE: 6,
  LEVEL_ASK_INPUT_TEXT: 7,
  LEVEL_BACK_YES: 8,
  LEVEL_CONFIRM_FOOD: 9,
  LEVEL_CONFIRM_FOOD_BETWEEN_MEALS: 10,
  LEVEL_ASK_NORMAL_FOOD: 11
};

const {
  LEVEL_NULL,
  LEVEL_DATE,
  LEVEL_FOOD,
  LEVEL_WAKEUP_TIME,
  LEVEL_ASK_ATE,
  LEVEL_CONFIRM_ATE,
  LEVEL_ASK_ATE_WITH_SOMEONE,
  LEVEL_ASK_INPUT_TEXT,
  LEVEL_ASK_NORMAL_FOOD,
  LEVEL_BACK_YES,
  LEVEL_NEXT_DAYTIME,
  LEVEL_CONFIRM_FOOD,
  LEVEL_CONFIRM_FOOD_BETWEEN_MEALS
} = CONSTANTS_LEVEL;

// We define here the poll path for each daytime

const DAYTIME_POLL_PATH = {
  [DAYTIME_BREAKFAST]: [LEVEL_ASK_ATE, {
    yes: [LEVEL_ASK_ATE_WITH_SOMEONE, { yes: [LEVEL_FOOD, LEVEL_CONFIRM_FOOD], no: [LEVEL_FOOD, LEVEL_CONFIRM_FOOD] }],
    no: [LEVEL_CONFIRM_ATE, {
      yes: [LEVEL_NEXT_DAYTIME],
      no: [LEVEL_BACK_YES]
    }]
  }],
  [DAYTIME_MIDMORNING]: [LEVEL_ASK_ATE, {
    yes: [LEVEL_ASK_ATE_WITH_SOMEONE, { yes: [LEVEL_FOOD, LEVEL_CONFIRM_FOOD], no: [LEVEL_FOOD, LEVEL_CONFIRM_FOOD] }],
    no: [LEVEL_CONFIRM_ATE, {
      yes: [LEVEL_NEXT_DAYTIME],
      no: [LEVEL_BACK_YES]
    }]
  }],
  [DAYTIME_LUNCH]: [LEVEL_ASK_ATE, {
    yes: [LEVEL_ASK_ATE_WITH_SOMEONE, { yes: [LEVEL_FOOD, LEVEL_CONFIRM_FOOD], no: [LEVEL_FOOD, LEVEL_CONFIRM_FOOD] }],
    no: [LEVEL_CONFIRM_ATE, {
      yes: [LEVEL_NEXT_DAYTIME],
      no: [LEVEL_BACK_YES]
    }]
  }],
  [DAYTIME_AFTERNOON_SNACK]: [LEVEL_ASK_ATE, {
    yes: [LEVEL_ASK_ATE_WITH_SOMEONE, { yes: [LEVEL_FOOD, LEVEL_CONFIRM_FOOD], no: [LEVEL_FOOD, LEVEL_CONFIRM_FOOD] }],
    no: [LEVEL_CONFIRM_ATE, {
      yes: [LEVEL_NEXT_DAYTIME],
      no: [LEVEL_BACK_YES]
    }]
  }],
  [DAYTIME_DINNER]: [LEVEL_ASK_ATE, {
    yes: [LEVEL_ASK_ATE_WITH_SOMEONE, { yes: [LEVEL_FOOD, LEVEL_CONFIRM_FOOD, LEVEL_ASK_NORMAL_FOOD, LEVEL_CONFIRM_FOOD_BETWEEN_MEALS], no: [LEVEL_FOOD, LEVEL_CONFIRM_FOOD, LEVEL_ASK_NORMAL_FOOD, LEVEL_CONFIRM_FOOD_BETWEEN_MEALS] }],
    no: [LEVEL_CONFIRM_ATE, {
      yes: [LEVEL_NULL],
      no: [LEVEL_BACK_YES]
    }]
  }]
};

export {
  CONSTANTS_DAYTIME,
  CONSTANTS_LEVEL,
  DAYTIME_POLL_PATH
};
