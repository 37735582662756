import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import esSVG from './assets/esp_idioma.svg';
import caSVG from './assets/cat_idioma.svg';
import euSVG from './assets/eusk_idioma.svg';
import glSVG from './assets/gallego_idioma.svg';

i18n
// .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    fallbackLng: {
      default: ['es']
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    cleanCode: true,
    defaultNS: 'translation',
    lng: 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false // react alr eady safes from xss
    },
    preload: ['es', 'ca', 'eu', 'gl'],
    supportedLngs: ['es', 'ca', 'eu', 'gl'],
    react: {
      bindI18nStore: 'added'
    }
  });

const flags = {
  es: {
    on: esSVG,
    name: 'Español',
    code: 'es'
  },
  ca: {
    on: caSVG,
    name: 'Català',
    code: 'ca'
  },
  eu: {
    on: euSVG,
    name: 'Euskara',
    code: 'eu'
  },
  gl: {
    on: glSVG,
    name: 'Galego',
    code: 'gl'
  }
};

export {
  flags
};

export default i18n;
