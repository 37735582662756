import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import './MyMealsRecord.css';
import { useShow } from '../hooks/useShow';
import { CONSTANTS_DAYTIME } from '../constants';

import crossSVG from '../assets/remove_x.svg';
import editSVG from '../assets/edit.svg';
import trashSVG from '../assets/remove.svg';
import minimizeSVG from '../assets/up_arrow.svg';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

const {
  DAYTIME_BREAKFAST,
  DAYTIME_MIDMORNING,
  DAYTIME_LUNCH,
  DAYTIME_AFTERNOON_SNACK,
  DAYTIME_DINNER
} = CONSTANTS_DAYTIME;

const MyMealsRecord = ({
  items,
  onRemoveFood,
  onEditFood,
  portionsDictionary,
  onRemoveDayTime,
  disableEditButtons,
  disableRemoveButtons,
  dayTime,
  onClickFinish,
  onClickEdit,
  pollAnswers
}) => {
  const { show, handleTouch } = useShow(false);
  const { t } = useTranslation();

  const itemsSorted = items.sort((a, b) => a.dayTime - b.dayTime);

  console.log(itemsSorted, 'itemsSorted');

  const [showCategory, setShowCategory] = useState({
    [DAYTIME_BREAKFAST]: true,
    [DAYTIME_MIDMORNING]: true,
    [DAYTIME_LUNCH]: true,
    [DAYTIME_AFTERNOON_SNACK]: true,
    [DAYTIME_DINNER]: true
  });

  const dayTimesText = {
    [DAYTIME_BREAKFAST]: t('breakfast'),
    [DAYTIME_MIDMORNING]: t('midmorning'),
    [DAYTIME_LUNCH]: t('lunch'),
    [DAYTIME_AFTERNOON_SNACK]: t('afternoon_snack'),
    [DAYTIME_DINNER]: t('dinner')
  };

  const handleTouchMinimize = (dayTime) => {
    setShowCategory({ ...showCategory, [dayTime]: !showCategory[dayTime] });
  };

  const handleDisplay = () => {
    handleTouch();
  };

  const DayTimeMessage = ({ record, pollAnswers }) => {
    if (pollAnswers[record.dayTime].date !== null) {
      return <span className='daytime-item daytime-item__bubble'>{pollAnswers[record.dayTime].date + 'h'}</span>;
    } else if (record.food.length === 0) {
      return <span className='daytime-item'>{t('no_food_in_record')}</span>;
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <div className={clsx('my-meals-box__wrapper', show && 'my-meals-box__wrapper--active')}>
        <div className={clsx('my-meals-box', show && 'my-meals-box--displayed')}>
          <button onClick={() => handleDisplay()}></button>
          <span>{t('my_meals')}</span>
        </div>
        <div className={clsx('my-meals-box__inner', show && 'my-meals-box__inner--displayed')}>
          {
            itemsSorted.map((record, i) => {
              console.log(record, '=>', showCategory[record.dayTime]);
              return (
                <div key={i}>
                  <div className='daytime-title'>
                    <span>{dayTimesText[record.dayTime]}</span>
                    <button
                      onClick={() => onClickEdit(record.dayTime)}
                      type='button'
                    >
                      <object type='image/svg+xml' data={editSVG} />
                    </button>
                    <button type='button' onClick={() => onRemoveDayTime(record.dayTime)}><object type='image/svg+xml' data={trashSVG}/></button>
                    <button type='button' onClick={() => handleTouchMinimize(record.dayTime)}>
                      <object type='image/svg+xml' data={minimizeSVG}/>
                    </button>
                  </div>
                  {
                    showCategory[record.dayTime] && (
                      <Fragment>
                        <DayTimeMessage
                          record={record}
                          pollAnswers={pollAnswers}
                        />
                        <TransitionGroup>
                        {
                          record.food.map((food, j) => {
                            const lastFoodIDinPath = food.pathIDs[food.pathIDs.length - 1];
                            return (
                              <CSSTransition
                                key={j}
                                nodeRef={food.nodeRef}
                                timeout={500}
                                classNames="food-wrapper"
                              >
                                <div ref={food.nodeRef}>
                                  <div className='food-item'>
                                    <img
                                      src={`./locales/assets/foods/${lastFoodIDinPath}.png`}
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = './locales/assets/foods/placeholder.png';
                                      }}
                                    />
                                    <span className='food-item__info'>
                                      <span>
                                        {`${portionsDictionary[food.portion]}`}
                                      </span>
                                      <span>
                                        {`${food.getName(food.pathIDs)}`}
                                      </span>
                                    </span>
                                  </div>
                                  <div className='food-item-actions'>
                                    <button
                                      type='button'
                                      disabled={disableEditButtons}
                                      onClick={() => onEditFood(record.dayTime, food)}
                                    >
                                      Editar
                                    </button>
                                    <div></div>
                                    <button
                                      type='button'
                                      disabled={disableRemoveButtons}
                                      onClick={() => onRemoveFood(record.dayTime, food)}
                                    >
                                      Eliminar
                                    </button>
                                  </div>
                                </div>
                              </CSSTransition>
                            );
                          })
                        }
                        </TransitionGroup>
                      </Fragment>
                    )
                  }
                </div>
              );
            })
          }
        </div>
        <div className='my-meals-box__inner__btn-wrapper'>
          <button
            onClick={() => onClickFinish(dayTime)}
            className='btn btn-primary'
            disabled={dayTime !== DAYTIME_DINNER}
          >
            {
              t('finish')
            }
          </button>
        </div>
      </div>
      <div className={clsx('my-meals-background', show && 'my-meals-background--activated ')}/>
    </Fragment>
  );
};

export default MyMealsRecord;
