import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import './TimePicker.css';
// import format from 'date-fns/format';

const TimePicker = ({ date, onChangeDate, visibleDropdown, type = 'big' }) => {
  const currentHour = parseInt(date.split(':')[0]);
  const currentMinutes = parseInt(date.split(':')[1]);
  const [visible, setVisible] = useState(false);

  const inputRef = useRef(null);

  const handleOnBlur = (event) => {
    const { relatedTarget } = event;

    if (relatedTarget && relatedTarget.id === 'time-num-btn') {
      event.preventDefault();
    } else {
      setVisible(false);
    }
  };

  return (
    <div className='time-picker'>
      <input
        ref={inputRef}
        onFocus={(event) => setVisible(true)}
        onBlur={(event) => handleOnBlur(event) }
        value={date}
        onChange={(e) => onChangeDate(e.target.value)}
        type='time'
        required
      />
      {
        type === 'big' && (<span className='time-picker__input-icon'>h</span>)
      }
      {visible && visibleDropdown && (
        <div className='time-picker__dropdown'>
          <div className='time-picker__dropdown__hours'>
            {
              Array.from({ length: 24 }, (_, i) => i).map((hour) => {
                const newHour = hour <= 9 ? `0${hour}` : hour;
                const newCurrentMinutes = currentMinutes <= 9 ? `0${currentMinutes}` : currentMinutes;
                return (
                  <button
                    key={hour}
                    id={'time-num-btn'}
                    className={clsx(hour === currentHour && 'active')}
                    onClick={() => {
                      inputRef.current.focus();
                      onChangeDate(`${newHour}:${newCurrentMinutes}`);
                    }}
                  >
                    {newHour}
                  </button>
                );
              })
            }
          </div>
          <div className='time-picker__dropdown__minutes'>
            {
              Array.from({ length: 60 / 5 }, (_, i) => i).map((minute) => {
                const minuteValue = minute * 5;
                const newMinute = minuteValue <= 9 ? `0${minuteValue}` : minuteValue;
                const newCurrentHour = currentHour <= 9 ? `0${currentHour}` : currentHour;

                return (
                  <button
                    key={minuteValue}
                    id={'time-num-btn'}
                    className={clsx(minuteValue === currentMinutes && 'active')}
                    onClick={() => {
                      inputRef.current.focus();
                      onChangeDate(`${newCurrentHour}:${newMinute}`);
                    }}
                  >
                    {newMinute}
                  </button>
                );
              }
              )
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
