import PropTypes from 'prop-types';

import clsx from 'clsx';
import './Modal.css';

const ModalTopImage = ({ data }) => {
  return <object className='modal-content__header-image' type='image/svg+xml' data={data}></object>;
};

const ModalTitle = ({ children }) => {
  return <span className='modal-content__title'>{children}</span>;
};

const ModalDesc = ({ children }) => {
  return <span className='modal-content__desc'>{children}</span>;
};

const ModalFoodItem = ({ children }) => {
  return <span className='modal-content__food-item'>{children}</span>;
};

const ModalSubTitle = ({ children }) => {
  return <span className='modal-content__sub-title'>{children}</span>;
};

const Modal = ({ children, visible, size }) => {
  return (
    <div className={clsx('modal-wrapper', visible && 'modal-wrapper--show')}>
      <div className={clsx('modal-content', size === 'large' && 'modal-content--large')}>
        {children}
      </div>
    </div>
  );
};

export default Modal;

export {
  ModalDesc,
  ModalTitle,
  ModalTopImage,
  ModalFoodItem,
  ModalSubTitle
};
