import { createRoot } from 'react-dom/client';
import './i18n';

import './styles/animations.css';
import './styles/app.css';
import './styles/global.css';
import './styles/fonts.css';

import { App } from './App';

import './styles/date-picker.scss';

import { BrowserRouter } from 'react-router-dom';

import AUTH_CONTEXT_PROVIDER from './context/AuthProvider';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <AUTH_CONTEXT_PROVIDER>
      <App />
    </AUTH_CONTEXT_PROVIDER>
  </BrowserRouter>
);
